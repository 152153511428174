<!--Contenedor-->
<div class="container align-items-center flex-column">

  <!--Encabezado-->
  <div class="row justify-content-between py-2">
    <div class="col-md-4 col-sm-12">
      <h5 class="title-section">Perfil de Usuario</h5>
    </div>
    <div class="col-md-4 col-sm-12 text-right">
      <button class="btn icon-btn text-right bg-secondary" ngbTooltip="Regresar" [routerLink]="['/menu']">
        <!--la-icon [icon]="['las','arrow-left']" size="1x" class="text-white"></la-icon-->
        <i class="las la-arrow-left text-white"></i>
      </button>
    </div>
  </div>

  <!--Formulario-->
  <form [formGroup]="fprincipal">
    <div class="row text-dark">
      <input class="form-control" type="hidden" formControlName="idUsuario" [(ngModel)]="mprincipal.idUsuario">
    </div>

    <div class="row text-dark">
      <input class="form-control" type="hidden" formControlName="idUsuario" [(ngModel)]="mprincipal.idUsuario">
      <input class="form-control" type="hidden" formControlName="digito" [readonly]="true"
        [(ngModel)]="mprincipal.digito">
      <input class="form-control" type="hidden" formControlName="claveCoordinacion" [readonly]="true"
        [(ngModel)]="mprincipal.claveCoordinacion">
      <input class="form-control" type="hidden" formControlName="claveDepartamento" [readonly]="true"
        [(ngModel)]="mprincipal.claveDepartamento">
    </div>

    <div class="row text-dark">
      <div class="form-group col-lg-4">
        <label for="cuenta">Cuenta (Dígito):</label>
        <input class="form-control" type="text" formControlName="cuenta" [readonly]="true"
          [(ngModel)]="mprincipal.cuenta">
      </div>
      <div class="form-group col-lg-4">
        <label for="cuenta">Tipo Usuario:</label>
        <select class="form-control" formControlName="idTipoUsuario" [(ngModel)]="mprincipal.idTipoUsuario">
          <option value="">--Seleccionar--</option>
          <option *ngFor="let item of TipoUsuarioLista" [ngValue]="item.idTipoUsuario">
            {{ item.descripcion }}
          </option>
        </select>
      </div>
      <div class="form-group col-lg-4">
        <label for="email">Correo electrónico:</label>
        <input class="form-control" type="text" formControlName="email" [readonly]="true"
          [(ngModel)]="mprincipal.email">
      </div>
    </div>

    <div class="row text-dark">
      <div class="form-group col-lg-4">
        <label for="nombre">Nombre:</label>
        <input class="form-control" type="text" formControlName="nombre" [readonly]="true"
          [(ngModel)]="mprincipal.nombre">
      </div>
      <div class="form-group col-lg-4">
        <label for="apPat">Apellido Paterno:</label>
        <input class="form-control" type="text" formControlName="apPat" [readonly]="true"
          [(ngModel)]="mprincipal.apPat">
      </div>
      <div class="form-group col-lg-4">
        <label for="apMat">Apellido Materno:</label>
        <input class="form-control" type="text" formControlName="apMat" [readonly]="true"
          [(ngModel)]="mprincipal.apMat">
      </div>
    </div>
    <div class="row text-dark">
      <div class="form-group col-lg-6">
        <label for="coordinacion">Coordinación:</label>
        <input class="form-control" type="text" formControlName="coordinacion" [readonly]="true"
          [(ngModel)]="mprincipal.coordinacion">
      </div>
      <div class="form-group col-lg-6">
        <label for="departamento">Departamento:</label>
        <input class="form-control" type="text" formControlName="departamento" [readonly]="true"
          [(ngModel)]="mprincipal.departamento">
      </div>
    </div>


  </form>


</div>
