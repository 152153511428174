import { NgxSpinnerModule } from 'ngx-spinner';
import { element } from 'protractor';
import { GChartConfig } from './../../../GChartConfig';
import { ChartSelectionChangedEvent, ChartType, GoogleChartsConfig } from 'angular-google-charts';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { NgbCalendar, NgbDate, NgbDatepickerConfig, NgbDateStruct, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { UsuarioService } from 'src/app/data-access/usuario.service';
import { DiaInhabilService } from 'src/app/data-access/diainhabil.service';
import { EspacioTipoService } from 'src/app/data-access/espaciotipo.service';
import { EspacioService } from 'src/app/data-access/espacio.service';
import { ReservacionService } from 'src/app/data-access/reservacion.service';
import { EquipoService } from 'src/app/data-access/equipo.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Location } from '@angular/common'
import * as util from 'src/app/data-access/util';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-timeline',
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.css']
})

export class TimelineComponent implements OnInit {
  @ViewChild('AvisoDePrivacidad') aviso: any;
  chart: GChartConfig;
  filtroFecha: NgbDateStruct;

  forma: FormGroup;
  model;
  NombreCompleto;
  p: number = 1;


  EspacioD = undefined;
  FechaD = undefined;
  HorarioD = undefined;
  HorarioFinD = undefined;

  CalMinDate;
  datePickerJson = {};
  markDisabled;
  Usuario;
  idReservationAEditar: Number;

  gHorario = [];
  json = { disable: undefined, disabledDates: undefined };
  DiaInhabil;
  DiaInhabilLista;
  Espacio;
  EspacioLista;
  Equipo;
  EquipoLista;
  Reservacion;
  ReservacionLista;
  HoraInicioLista;
  HoraFinLista;
  EquiposDisponibles;
  hCancelar;
  isDisabled;
  minDate: Date = new Date();
  bEdicion = undefined;
  ShowBotonGuardar: boolean;
  ShowBotonCancelar: boolean;
  NoActualizarHorariosInicio: boolean;
  ShowBotonConfirmar: boolean;
  constructor(
    public fb: FormBuilder,
    private usuarioService: UsuarioService,
    private diaInhabilService: DiaInhabilService,
    private espacioService: EspacioService,
    private reservacionService: ReservacionService,
    private equipoService: EquipoService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    public modal: NgbModal,
    private calendar: NgbCalendar,
    private location: Location
  ) {

    this.Usuario = this.usuarioService.getUser();
    const current = new Date();
    this.CalMinDate = {
      year: current.getFullYear(),
      month: current.getMonth() + 1,
      day: current.getDate()
    };
    this.addHorarioDia();
    let user = this.usuarioService.getUser();
    this.NombreCompleto = user.nombre + ' ' + user.apPat + ' ' + user.apMat;
    this.iniciarForm();

    //this.MostrarAvisoDePrivacidadSiNoLoHaVistoYSiNoEsAdministrador();

    /*
        Google chart
     */
    this.chart = new GChartConfig();
    this.chart.type = ChartType.Timeline;
    this.chart.title = "Time line";
    this.chart.columnNames = [
      { type: 'string', id: 'Room' },
      { type: 'string', id: 'Name' },
      { type: 'date', id: 'Start' },
      { type: 'date', id: 'End' },
    ];
    this.chart.data = [];

    this.chart.options = {
      timeline: { colorByRowLabel: true },
      tooltip: { trigger: 'none' }
    };
  }

  ngOnInit(): void {
    this.addDiasInhabiles();
    this.addEspacio();
    this.addEquipo();
  }

  goBack() {
    this.location.back()
  }

  /*
    MAIN ACTIONS
   */
  Evento_SeleccionarNuevaFechaFiltro() {
    this.InicializarReservacionesGoogleChart();
  }

  Evento_MostrarFormularioParaGenerarNuevaReservacion(content) {
    this.espacioNoDisponible = false
    this.iniciarForm();
    this.restoreEspacioLista();
    this.model.activo = true;
    this.hCancelar = true;
    this.bEdicion = undefined;
    this.FechaD = true;
    this.HorarioD = true;
    this.HorarioFinD = true;
    this.ShowBotonGuardar = true;
    this.ShowBotonCancelar = false;
    this.ShowBotonConfirmar = false;
    this.NoActualizarHorariosInicio = false;
    this.modal.open(content, { size: 'xl' });
  }

  Evento_ConfirmarReservacion() {
    this.spinner.show();

    this.reservacionService.GetReservacion(this.idReservationAEditar).toPromise().then(element => {
      this.Evento_GuardarReservacion(element.idEstatus, true);
    });


  }

  Evento_GuardarReservacion(idEstatus, confirmar = false) {
    this.spinner.show();
    this.dEnable(true);
    var dia = this.forma.controls["dia"].value;
    var hinicio = this.forma.controls["horaInicio"].value;
    var hfin = this.forma.controls["horaFin"].value;
    var idEspacio = this.forma.controls["idEspacio"].value;
    var dInicio = this.formatDate(dia);
    var inicio = dInicio + 'T' + hinicio + ':00';
    var fin = dInicio + 'T' + hfin + ':00';
    this.forma.controls["inicio"].setValue(inicio);
    this.forma.controls["fin"].setValue(fin);
    this.forma.controls["idUsuario"].setValue(this.Usuario.idUsuario);

    //Si no es una cancelación, determinamos el estatus según el espacio
    if (idEstatus != 3) {
      let espacio = this.Espacio.find(x => x.idEspacio === idEspacio);
      if (espacio.autorizacion) { idEstatus = 1; }
    }

    this.forma.controls["idEstatus"].setValue(idEstatus);



    let arr = [];
    /*this.EquiposDisponibles = arr;
    for (let e of this.EquiposDisponibles) {
      if (e.cant > 0) {
        e.cantidad = e.cant;
        arr.push(e);
      }
    }*/

    this.forma.controls["equipos"].setValue(arr);

    if (this.forma.controls["idReservacion"].value == undefined) {
      this.reservacionService.addData(this.forma.value).subscribe(
        res => {
          this.recargar();
          this.spinner.hide();
          this.toastr.success("La información ha sido enviada");
        },
        error => {
          if (typeof error.error === 'object') {
            this.toastr.error(error.message);
          } else {
            this.toastr.error(error.error);
          }
          this.spinner.hide();
        });
    }
    else {
      this.reservacionService.GetReservacion(this.idReservationAEditar).toPromise().then(element => {
        console.log(element);
        this.forma.controls["idEstatus"].setValue(element.idEstatus);

        this.reservacionService.updData(this.forma.controls["idReservacion"].value, this.forma.value).subscribe(
          (res) => {
            this.toastr.success("La información ha sido actualizada");
            this.recargar();
            if (confirmar) {
              this.reservacionService.ConfirmarReservacion(this.idReservationAEditar).toPromise().then(element => {
                this.toastr.success("Reservación ha sido confirmada");
              });
            }

            this.spinner.hide();
          },
          (error) => { this.toastr.error(error.error.message); console.log(error); this.spinner.hide(); });
      });

    }

  }

  Evento_SeleccionarReservacionParaEdicion(event, content) {
    let rowId = event.selection[0].row;
    let reservation = this.chart.data[rowId];
    this.idReservationAEditar = reservation[1].replace('#', '');
    this.Evento_MostrarFormularioParaEditarReservacion(content);
  }


  Evento_MostrarFormularioParaEditarReservacion(content) {

    if (this.UserSelectedInvalidReservation()) { return; }

    let user = this.usuarioService.getUser();
    let idUsuario = user.idUsuario;
    this.ShowBotonGuardar = false;

    

    this.reservacionService.EvaluarReservacion(this.idReservationAEditar, idUsuario)
      .toPromise()
      .then(elements => {
        console.log(elements);
        if (elements.mostrarMensajeDeError) {
          this.toastr.error(elements.mensajeDeError);
          return false;
        }

        this.ShowBotonGuardar = elements.mostrarBotonGuardar;
        this.ShowBotonCancelar = elements.mostrarBotonCancelar;
        this.NoActualizarHorariosInicio = elements.noActualizarListadoDeHorariosInicio;
        this.ShowBotonConfirmar = elements.mostrarBotonConfirmar;

        this.reservacionService.GetReservacion(this.idReservationAEditar)
          .toPromise()
          .then(data => {
            this.model.comentarios = data.comentarios;
            this.model.idEspacio = data.idEspacio;
            let elements = data.inicio.split('T');
            let fechaElement = elements[0];
            let tiempo = elements[1];
            let anio = fechaElement.split('-')[0];
            let mes = fechaElement.split('-')[1];
            let dia = fechaElement.split('-')[2];

            let tiempofin = data.fin.split('T')[1];
            this.model.dia = dia + '/' + mes + '/' + anio;
            this.model.horaInicio = tiempo.split(':')[0] + ':' + tiempo.split(':')[1];
            this.model.horaFin = tiempofin.split(':')[0] + ':' + tiempofin.split(':')[1];
            this.model.inicio = data.inicio;
            this.model.fin = data.fin;
            this.model.idUsuario = this.Usuario.idUsuario;
            this.model.idEstatus = data.idEstatus;
            this.model.idReservacion = data.idReservacion;
            this.Evento_EditarReservacion(content, this.model);
          });
      });
  }

  InicializaFormularioConData(data): FormGroup {

    let formulario = this.FormularioLimpio();
    formulario.controls["idEspacio"].setValue(data.idEspacio);
    let elements = data.inicio.split('T');
    let fechaElement = elements[0];
    let tiempo = elements[1];

    let anio = fechaElement.split('-')[0];
    let mes = fechaElement.split('-')[1];
    let dia = fechaElement.split('-')[2];

    formulario.controls["dia"].setValue(dia + '/' + mes + '/' + anio);
    formulario.controls["horaInicio"].setValue(tiempo);
    formulario.controls["horaFin"].setValue(data.fin.split('T')[1]);
    var dInicio = this.formatDate(dia);
    var inicio = dInicio + tiempo;
    var fin = dInicio + 'T' + data.fin.split('T')[1];

    formulario.controls["inicio"].setValue(inicio);
    formulario.controls["fin"].setValue(fin);
    formulario.controls["idUsuario"].setValue(this.Usuario.idUsuario);
    formulario.controls["idEstatus"].setValue(data.idEstatus);
    return formulario;
  }

  Evento_EditarReservacion(content, modelo) {
    this.espacioNoDisponible = false
    this.HoraInicioLista = [modelo.horaInicio];
    this.HoraFinLista = [modelo.horaFin];
    this.model = modelo;
    this.restoreEspacioLista();
    this.hCancelar = false;
    //this.bEdicion = true;
    this.model.dia = new Date(modelo.inicio);
    this.forma = this.model;

    /*Inicializar Horarios Inicio */
    var dia = this.model.dia;
    var diaSel = this.formatDate(dia);

    if (!this.NoActualizarHorariosInicio) {
      this.reservacionService.getHorariosInicio({ IdEspacio: this.model.idEspacio, FechaReservacion: diaSel, idReservacion: this.model.idReservacion }).subscribe(
        res => {

          this.HoraInicioLista = res;
          this.HorarioD = undefined;
          this.spinner.hide();
          //this.forma.disable();//DAAE Disable
        },
        error => {
          if (typeof error.error === 'object') {
            this.toastr.error(error.message);
          } else {
            this.toastr.error(error.error);
          }
          this.spinner.hide();
        }
      );

    }

    /**
     * Inicializar Horarios Fin 
     */
    this.HorarioFinD = true;
    if (this.model.horaInicio != '' && this.model.horaInicio !== undefined) {
      var dia = this.model.dia;
      var diaSel = this.formatDate(dia);
      var horaInicio = this.model.horaInicio;

      this.spinner.show();
      this.reservacionService.getHorariosFin({ IdEspacio: this.model.idEspacio, FechaReservacion: diaSel, HorarioInicio: horaInicio }).subscribe(
        res => {
          this.HoraFinLista = res;
          this.HorarioFinD = undefined;
          this.spinner.hide();
        },
        error => {
          if (typeof error.error === 'object') {
            this.toastr.error(error.message);
          } else {
            this.toastr.error(error.error);
          }
          this.spinner.hide();
        }
      );
    }

    this.dEnable(false);
    this.modal.open(content, { size: 'xl' });

  }

  Evento_Cancelar() {
    Swal.fire({
      title: 'Cancelar Reservacion',
      input: 'textarea',
      text: "Se cancelará la reservación. Déjanos un comentario con el motivo de la cancelación.",
      icon: 'warning',
      inputAttributes: {
        autocapitalize: 'off'
      },
      showCancelButton: true,
      confirmButtonColor: '#dc3545',
      confirmButtonText: 'Cancelar Reservación',
      cancelButtonText: 'Cancelar',
      showLoaderOnConfirm: true,
      preConfirm: (comentario) => {
        this.spinner.show();
        this.reservacionService.cancelarReservacion({ IdReservacion: this.model.idReservacion, MotivoCancelacion: comentario }).subscribe(
          res => {
            this.spinner.hide();
            this.toastr.success("La reservación ha sido cancelada"); this.recargar();
          },
          error => {
            if (typeof error.error === 'object') {
              this.toastr.error(error.message);
            } else {
              this.toastr.error(error.error);
            }
            this.spinner.hide();
          }
        );
        return true;
      }
    });
  }


  /**
   * FRONTEND HELPERS
   */
  UserSelectedInvalidReservation(): boolean {
    return this.idReservationAEditar == 0;
  }

  InicializarReservacionesGoogleChart() {
    this.spinner.show();
    let selectedDate = this.filtroFecha;
    this.reservacionService.getDataByDate(selectedDate).toPromise()
      .then(data => {
        this.spinner.hide();

        this.Reservacion = data;
        this.ReservacionLista = this.Reservacion;

        this.chart.data = [];
        let today = new Date();

        if (selectedDate == undefined)
          today = new Date();
        else {
          let date = JSON.stringify(selectedDate).split('T')[0].replace("\"", "");
          let dateElements = date.split('-');
          today = new Date(Number(dateElements[0]), Number(dateElements[1]) - 1, Number(dateElements[2]));
        }

        for (let i = 0; i < this.Espacio.length; i++) {
          let currentEspacio = this.Espacio[i];
          this.chart.data.push([currentEspacio.nombre, '', new Date(today.getFullYear(), today.getMonth(), today.getDate(), 8, 0, 0), new Date(today.getFullYear(), today.getMonth(), today.getDate(), 8, 0, 0)]);
          this.chart.data.push([currentEspacio.nombre, '', new Date(today.getFullYear(), today.getMonth(), today.getDate(), 21, 0, 0), new Date(today.getFullYear(), today.getMonth(), today.getDate(), 21, 0, 0)]);
        }

        for (let i = 0; i < this.ReservacionLista.length; i++) {
          let currentReservacion = this.ReservacionLista[i];
          let fechaInicio = this.ConvertStringToDate(currentReservacion.inicio);
          let fechaFin = this.ConvertStringToDate(currentReservacion.fin);
          this.chart.data.push([currentReservacion.espacio, "#" + currentReservacion.idReservacion, fechaInicio, fechaFin]);
        }


      });
  }

  recargar() {
    this.modal.dismissAll();
    this.InicializarReservacionesGoogleChart();
  }

  iniciarForm() {
    this.forma = this.FormularioLimpio();
    this.model = this.forma;
  }



  FormularioLimpio(): FormGroup {
    return this.fb.group({
      idReservacion: [0],
      idEspacio: [0, [Validators.required]],
      idUsuario: [0],
      dia: ['', [Validators.required]],
      horaInicio: ['', [Validators.required]],
      horaFin: ['', [Validators.required]],
      inicio: [''],
      fin: [''],
      idEstatus: [0],
      equipos: [''],
      equipo: [''],
      comentarios: ['']
    });
  }

  addHorarioDia() {
    for (let i = 0; i <= 23; i++) {
      let h = (i < 10 ? '0' + i : i)
      //this.gHorario.push(h + ':00');
      this.gHorario.push({ i: i, hora: h + ':00' });

    }
  }

  addDiasInhabiles() {
    this.spinner.show();
    this.diaInhabilService.getData().toPromise()
      .then(data => {
        this.spinner.hide();
        this.DiaInhabil = data;
        let disDates = [];
        for (let i = 0; i < this.DiaInhabil.length; i++) {
          let arrDia = this.DiaInhabil[i].dia.split('T')[0].split('-');
          disDates.push({ year: Number(arrDia[0]), month: Number(arrDia[1]), day: Number(arrDia[2]) });
        }
        this.json.disable = [6, 7];
        this.json.disabledDates = disDates;

        this.disableDays();
      });
  }


  addEspacio() {
    this.spinner.show();
    this.espacioService.getData().toPromise()
      .then(data => {
        this.spinner.hide();

        this.Espacio = data;
        this.EspacioLista = this.Espacio;
        this.InicializarReservacionesGoogleChart();
      });
  }


  addEquipo() {
    this.spinner.show();
    this.equipoService.getData().toPromise()
      .then(data => {
        this.spinner.hide();
        this.Equipo = data;
        //display: 'Lámpara', value: 1
        for (let i = 0; i < this.Equipo.length; i++) {
          this.Equipo[i]['display'] = this.Equipo[i].nombre;
          this.Equipo[i]['value'] = this.Equipo[i].idEquipo;
        }
        this.EquipoLista = this.Equipo;
      });
  }




  restoreEspacioLista() {
    this.EspacioLista = this.Espacio;
  }

  setEspacio() {
    this.model.dia = '';
    this.model.horaInicio = '';
    this.model.horaFin = '';
    this.HorarioD = true;
    this.HorarioFinD = true;
    this.FechaD = true;
    this.slideUpEquipos();
    if (this.model.idEspacio != 0 && this.model.idEspacio !== undefined) {
      this.FechaD = undefined;
    }
  }

  espacioNoDisponible = false
  setHorario() {
    this.model.horaInicio = '';
    this.model.horaFin = '';
    this.HorarioD = true;
    this.HorarioFinD = true;
    this.slideUpEquipos();
    var dia = this.forma.controls["dia"].value;
    var diaSel = this.formatDate(dia);
    this.spinner.show();

    this.reservacionService.getHorariosInicio({ IdEspacio: this.model.idEspacio, FechaReservacion: diaSel }).subscribe(
      res => {
        this.espacioNoDisponible = false
        this.HoraInicioLista = res;
        this.HorarioD = undefined;
        this.spinner.hide();
      },
      error => {
        this.espacioNoDisponible = true
        if (typeof error.error === 'object') {
          this.toastr.error(error.message);
        } else {
          this.toastr.error(error.error);
        }
        this.spinner.hide();
      }
    );

  }

  setHoraFin() {

    this.model.horaFin = '';
    this.HorarioFinD = true;
    this.slideUpEquipos();
    if (this.model.horaInicio != '' && this.model.horaInicio !== undefined) {
      var dia = this.forma.controls["dia"].value;
      var diaSel = this.formatDate(dia);
      var horaInicio = this.forma.controls["horaInicio"].value;
      this.spinner.show();

      this.reservacionService.GetReservacion(this.idReservationAEditar).toPromise().then(element => {
        let hour = element.inicio.split('T')[1].split(':')[0];
        this.ShowBotonConfirmar = hour == horaInicio.split(':')[0];
      });


      this.reservacionService.getHorariosFin({ IdEspacio: this.model.idEspacio, FechaReservacion: diaSel, HorarioInicio: horaInicio }).subscribe(
        res => {
          this.HoraFinLista = res;
          this.HorarioFinD = undefined;
          this.spinner.hide();
        },
        error => {
          if (typeof error.error === 'object') {
            this.toastr.error(error.message);
          } else {
            this.toastr.error(error.error);
          }
          this.spinner.hide();
        }
      );
    }
  }


  setEquipos() {
    if (this.model.horaFin != '' && this.model.horaFin !== undefined) {
      const dia = this.forma.controls["dia"].value;
      const diaSel = this.formatDate(dia);
      const horaInicio = this.forma.controls["horaInicio"].value;
      const horaFin = this.forma.controls["horaFin"].value;
      this.spinner.show();
      this.equipoService.equiposDisponibles({ IdEspacio: this.model.idEspacio, FechaReservacion: diaSel, HorarioInicio: horaInicio, HorarioFin: horaFin }).subscribe(
        res => {
          this.EquiposDisponibles = res.map(x => { x.cant = 0; return x });
          this.slideDownEquipos();
          this.spinner.hide();
        },
        error => {
          if (typeof error.error === 'object') {
            this.toastr.error(error.message);
          } else {
            this.toastr.error(error.error);
          }
          this.spinner.hide();
        }
      );
    } else {
      this.slideUpEquipos();
    }
  }

  /*
    HELPERS
   */
  slideDownEquipos() {
    $('#equipoContent').slideDown({
      start: () => {
        $('#equipoContent').css({ display: 'flex' });
      }
    });
  }

  slideUpEquipos() {
    $('#equipoContent').slideUp(400, () => {
      this.EquiposDisponibles = [];
    });
  }

  minusEquipo(item) {
    if (item.cant > 0) {
      item.cant--;
    }
  }

  plusEquipo(item) {
    if (item.cant < item.cantidad) {
      item.cant++;
    }
  }

  ConvertStringToDate(fechaRaw): Date {
    let fechaRawElements = fechaRaw.split('T');
    let fechaElement = fechaRawElements[0];
    let horaElement = fechaRawElements[1];

    let fechaElements = fechaElement.split('-');
    let anio = fechaElements[0];
    let mes = fechaElements[1];
    let dia = fechaElements[2];

    let horaElements = horaElement.split(':');
    let hora = horaElements[0];
    let minuto = horaElements[1];
    let segundo = horaElements[2];

    return new Date(anio, mes - 1, dia, hora, minuto, segundo);
  }

  uf_Date(obj) { return util.uf_Date(obj); }
  formatDate(obj) { return util.formatDate(obj); }
  isToday(obj) { return util.isToday(obj); }

  util_fdt(fecha) {
    var d = new Date(fecha);
    return d.toLocaleString();
  }


  disableDays() {
    //to disable specific date and specific weekdays
    this.isDisabled = (
      date: NgbDateStruct
      //current: { day: number; month: number; year: number }
    ) => {
      return this.json.disabledDates.find(x =>
        (new NgbDate(x.year, x.month, x.day).equals(date))
        || (this.json.disable.includes(this.calendar.getWeekday(new NgbDate(date.year, date.month, date.day))))
      )
        ? true
        : false;
    };
  }

  MostrarAvisoDePrivacidadSiNoLoHaVistoYSiNoEsAdministrador() {
    let cuentaDeUsuarioLoggeado = this.usuarioService.getUser().cuenta;
    this.usuarioService.getDataById(this.usuarioService.getUser().idUsuario).toPromise().then(result => {
      let resultado: any = result;

      if (!resultado.aviso) {
        this.VerAvisoDePrivacidad();
      };
    });
  }



  VerAvisoDePrivacidad() {
    Swal.fire({
      title: '<strong>Aviso de privacidad</strong>',
      icon: 'info',
      html:
        'Para ver nuestro aviso de privacidad, favor de ingresar <a href="../assets/IberoAvisoDePrivacidad2022.pdf">aquí</a>.',
      showCloseButton: true,
      showCancelButton: false,
      focusConfirm: false,
      confirmButtonText:
        '<i class="fa fa-thumbs-up"></i> Enterado',
      confirmButtonAriaLabel: 'Thumbs up, great!',
      cancelButtonText:
        '<i class="fa fa-thumbs-down"></i>',
      cancelButtonAriaLabel: 'Thumbs down'
    }).then((result) => {
      let cuentaDeUsuarioLoggeado = this.usuarioService.getUser().cuenta;
      this.usuarioService.MarcarUsuarioDeQueYaVioAvisoDePrivacidad(cuentaDeUsuarioLoggeado);
    });
  }


  dateIsValid(date: Date): boolean {
    return date >= this.minDate;
  }


  onlyToday(date: Date): boolean {
    var d = new Date();
    d.setHours(0, 0, 0, 0);

    return date >= d;
  }

  dEnable(flag){
    if(flag){
      this.forma.get('idEspacio')?.enable();
      this.forma.get('dia')?.enable();
      this.forma.get('horaInicio')?.enable();
      this.forma.get('horaFin')?.enable();
    }
    else{
      this.forma.get('idEspacio')?.disable();
      this.forma.get('dia')?.disable();
      this.forma.get('horaInicio')?.disable();
      this.forma.get('horaFin')?.disable();
    }
  }  

}
