import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { config } from './config';


@Injectable({
  providedIn: 'root'
})
export class EspacioService {
  user = [];
  constructor(private http: HttpClient) {
  }

  getData() {
    //return this.http.get('assets/json/espacio.json');
    return this.http.get(config.apiUrl + '/Espacio/');
  }

  //Alta
  addData(data: undefined): Observable<any> {
    return this.http.post(config.apiUrl + '/Espacio/', data);
  }

  //Cambio
  updData(id: undefined, data: undefined): Observable<any> {
    return this.http.put(config.apiUrl + '/Espacio/' + id, data);
  }



}
