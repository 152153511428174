import { Component, Inject, OnInit } from '@angular/core';
import { UsuarioService } from 'src/app/data-access/usuario.service'
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import { config } from 'src/app/data-access/config';
import { DOCUMENT } from '@angular/common';
import { AuthService } from 'src/app/data-access/auth.service';

@Component({
  selector: 'app-inicio',
  templateUrl: './inicio.component.html',
  styles: []
})
export class InicioComponent implements OnInit {

  constructor(@Inject(DOCUMENT) private document: Document,
    private authService: AuthService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private usuarioService: UsuarioService,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit() {
       
    
    this.activatedRoute.params.subscribe(params => {
      
      this.spinner.show();
      let usuarioDatos = params['id'];
      if (usuarioDatos == undefined) {
        this.document.location.href = config.webAppUrl;
      }
      else {
        let usuario = {
          idTipoUsuario: 0,
          cuenta: '',
          digito: '',
          token: '',
          nombre: '',
          apPat: '',
          apMat: '',
          email: '',
          claveDepartamento: 0,
          departamento: '',
          claveCoordinacion: 0,
          coordinacion: '',
          roles: '',
          estatus: true,
          dataURL: ''
        };

        //console.log(usuarioDatos);
        usuario.dataURL = usuarioDatos;
        

        //console.log("User account sent...");
        //console.log(usuario);
        //console.log("-------------------------------")
        this.usuarioService.validaCuenta(usuario).toPromise()
          .then(data => {
            
            // console.log(data);
            
            let user = data;
            //console.log(user);
            if(!user.estatus){
              this.router.navigate(['/inactivo']);
              this.spinner.hide();
              return true;
            }
            //console.log("data sent from webservice...");
            
            if(!usuario.estatus){
              this.router.navigate(['/inactivo']);
              this.spinner.hide();
              return true;
            }
            //console.log("--------------------------------------")
            this.usuarioService.addUser(user);
            localStorage.setItem('valido', '1');
            this.spinner.hide();
            this.authService.verifyExpirationTime()
            this.router.navigate(['/menu']);

            if (localStorage.getItem('fullNameUndefined') == '1') {
              localStorage.setItem('fullNameUndefined', '0');
              //location.reload();
            }

          }, error => {
            //console.log("Error returned from webservice...")
            console.log(error)
            //console.log("----------------------------------");
          })
      }
    });
  }

}
