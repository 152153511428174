import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { FooterComponent } from './footer/footer.component';
import { LoginComponent } from './login/login.component';
import { PerfilComponent } from './perfil/perfil.component';
import { InicioComponent } from './inicio/inicio.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { InactivoComponent } from './inactivo/inactivo.component';

@NgModule({
  declarations: [FooterComponent, LoginComponent, PerfilComponent, InicioComponent, NavMenuComponent, InactivoComponent],
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    FormsModule,
    NgbModule
  ],
  //exports: [FooterComponent, LoginComponent, PerfilComponent, InicioComponent, NavMenuComponent]
  exports: [NavMenuComponent]

})
export class ShellModule {
  constructor() {

  }
}
