import { ChartType } from "angular-google-charts";

export class GChartConfig {
    title: string;
    type: ChartType;
    data: any;
    columnNames: any;
    options: any;
    /**
     *
     */
    constructor() {
        this.title = "";
        this.type = ChartType.Timeline;


    }
}