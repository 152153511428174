<app-timeline></app-timeline>

<!--
<form [formGroup]="ffiltro">
<div class="row text-center mt-2 ml-1 mr-1">

  <div class="col-md-2">
    <h5>{{ viewDate | calendarDate:(view + 'ViewTitle'):'es' }}</h5>
  </div> 

  <div class="col-md-5 row">
    <div class="col-md-2">
      <label>Espacio</label>
    </div>
    <div class="col-md-4">
      <select class="form-control" formControlName="idEspacio" 
      [(ngModel)]="mfiltro.idEspacio" (change)="buscarEspacio()">
      <option value="">--Seleccionar--</option>
      <option *ngFor="let item of EspacioListaFiltro" [ngValue]="item.idEspacio">
        {{ item.nombre }}
      </option>
      </select> 
    </div>    
  </div>  

  <div class="col-md-5 text-center">
    <div class="btn-group">
      <div
        class="btn btn-danger"
        mwlCalendarPreviousView
        [view]="view"
        [(viewDate)]="viewDate"
        (viewDateChange)="closeOpenMonthViewDay()"
      >
      <la-icon [icon]="['las','angle-left']" class="text-white"></la-icon>
      </div>
      <div
        class="btn btn-outline-secondary"
        mwlCalendarToday
        [(viewDate)]="viewDate"
      >
        Hoy
      </div>
      <div
        class="btn btn-danger mr-2"
        mwlCalendarNextView
        [view]="view"
        [(viewDate)]="viewDate"
        (viewDateChange)="closeOpenMonthViewDay()"
      >
      <la-icon [icon]="['las','angle-right']" class="text-white"></la-icon>
      </div>
    </div>

    <div class="btn-group">
      <div
        class="btn btn-danger"
        (click)="setView(CalendarView.Month)"
        [class.active]="view === CalendarView.Month"
      >
        Mes
      </div>
      <div
        class="btn btn-danger"
        (click)="setView(CalendarView.Week)"
        [class.active]="view === CalendarView.Week"
      >
        Sem
      </div>
      <div
        class="btn btn-danger mr-2"
        (click)="setView(CalendarView.Day)"
        [class.active]="view === CalendarView.Day"
      >
        Día
      </div>
    </div>
    <div class="btn-group">
    <button class="btn btn-danger mr-1" ngbTooltip="Nueva Reservación" (click)="nuevo(content)">
      <la-icon [icon]="['las','calendar-plus']" class="text-white"></la-icon>
    </button>
    <button class="btn btn-danger mr-1" ngbTooltip="Reservaciones" [routerLink]="['/e-reservacion/']" >
      <la-icon [icon]="['las','list']" class="text-white"></la-icon>
    </button>
    <button class="btn btn-danger mr-1" ngbTooltip="Regresar" [routerLink]="['/menu']">
      <la-icon [icon]="['las','arrow-left']" class="text-white"></la-icon>
    </button>
    </div>
  </div>
</div>
</form>

<div [ngSwitch]="view" class="text-center mt-2 ml-2 mr-2">
  <mwl-calendar-month-view
    *ngSwitchCase="CalendarView.Month"
    [viewDate]="viewDate"
    [locale]="locale"
    [events]="events"
    [refresh]="refresh"
    [activeDayIsOpen]="false"
    (dayClicked)="dayClicked(content,$event)"
    (eventClicked)="eventClicked(content,$event)"
    (eventTimesChanged)="eventTimesChanged($event)"
    (beforeViewRender)="beforeMonthViewRender($event)"
  >
  </mwl-calendar-month-view>
  <mwl-calendar-week-view
    *ngSwitchCase="CalendarView.Week"
    [viewDate]="viewDate"
    [locale]="locale"
    [events]="events"
    [refresh]="refresh"
    (click)="calCliked(content,$event)"
    (dayHeaderClicked)="dayClicked(content,$event)"
    (eventClicked)="eventClicked(content,$event)"
    (eventTimesChanged)="eventTimesChanged($event)"
    (beforeViewRender)="beforeViewRender($event)"
  >
  </mwl-calendar-week-view>
  <mwl-calendar-day-view
    *ngSwitchCase="CalendarView.Day"
    [viewDate]="viewDate"
    [locale]="locale"
    [events]="events"
    [refresh]="refresh"
    (hourSegmentClicked)="calCliked(content,$event)"
    (eventClicked)="eventClicked(content,$event)"
    (eventTimesChanged)="eventTimesChanged($event)"
    (beforeViewRender)="beforeViewRender($event)"
  >
  </mwl-calendar-day-view>
</div>


<ng-template #modalContent let-close="close">
  <div class="modal-header">
    <h5 class="modal-title">Event action occurred</h5>
    <button type="button" class="close" (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div>
      Action:
      <pre>{{ modalData?.action }}</pre>
    </div>
    <div>
      Event:
      <pre>{{ modalData?.event | json }}</pre>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="close()">
      OK
    </button>
  </div>
</ng-template>


  
  <ng-template #content let-modal>
    <div class="modal-hidden">
      <button class="close" aria-label="close" type="button" (click)="modal.dismiss()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="card border-dark mb-0">
        <div class="card-header text-white text-center bg-dark">Reservación</div>
        <div class="card-body text-dark">
  
          <div class=" card-body row">
            <div class="col-12">
              <form [formGroup]="forma">
                <input class="form-control" type="hidden" formControlName="idReservacion" [(ngModel)]="model.idReservacion">
                <div class="form-group row">
                  <div class="col-md-1">
                    <label for="dia"><span class="text-danger">*</span>Espacio</label>
                  </div>
                  <div class="col-md-5">
                    <select class="form-control" formControlName="idEspacio" 
                    [(ngModel)]="model.idEspacio" (change)="setEspacio()" [attr.disabled]="bEdicion || EspacioD">
                    <option value="">--Seleccionar--</option>
                    <option *ngFor="let item of EspacioLista" [ngValue]="item.idEspacio">
                      {{ item.nombre }} (Capacidad: {{ item.capacidad }})
                    </option>
                    </select> 
                    <div class="text-danger" *ngIf="forma.controls['idEspacio'].touched && forma.controls['idEspacio'].errors?.required">Este campo es requerido</div>
                  </div>    
                  <div class="col-md-1">
                    <label for="dia"><span class="text-danger">*</span>Día</label>
                  </div>
                  <div class="col-md-5">
                    <div class="input-group">
                      <input class="form-control" dateFormat="dd/mm/yyyy" placeholder="dd/mm/yyyy" 
                        [readonly]="true" [(ngModel)]="model.dia" (dateSelect)="setHorario($event)"
                        [minDate]="CalMinDate" [markDisabled]="isDisabled"
                        id="dia" formControlName="dia" ngbDatepicker #d="ngbDatepicker">
                      <div class="input-group-append">
                        <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button" [attr.disabled]="bEdicion || FechaD"></button>
                      </div>
                      <select class="form-control" formControlName="horaInicio"
                        [(ngModel)]="model.horaInicio" (change)="setHoraFin()" [attr.disabled]="bEdicion || HorarioD">
                        <option value="">--Seleccionar--</option>
                        <option *ngFor="let item of HoraInicioLista" [ngValue]="item">
                          {{ item }}
                        </option>
                      </select>
                      <select class="form-control" formControlName="horaFin"
                      [(ngModel)]="model.horaFin" (change)="setEquipos()" [attr.disabled]="bEdicion || HorarioFinD">
                      <option value="">--Seleccionar--</option>
                      <option *ngFor="let item of HoraFinLista" [ngValue]="item">
                        {{ item }}
                      </option>
                      </select>                         
                    </div>
                    <div class="text-danger" *ngIf="(forma.controls['dia'].touched && forma.controls['dia'].errors?.required) || (forma.controls['horaInicio'].touched && forma.controls['horaInicio'].errors?.required) || (forma.controls['horaFin'].touched && forma.controls['horaFin'].errors?.required)">
                        Estos campos son requeridos
                    </div>
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-md-1">
                    <label for="dia">Comentario</label>
                  </div>
                  <div class="col-md-11">
                    <div class="input-group">
                    <textarea cols="40" rows="2"
                      class="form-control" formControlName="comentarios" [(ngModel)]="model.comentarios" [attr.disabled]="bEdicion"></textarea>
                    </div>
                  </div>
                </div>
                <div class="row mt-4" id="equipoContent">
                    <div class="col-12 mb-3"><label>Equipos Disponibles</label></div>
                    <div class="col-md-4" *ngFor="let item of EquiposDisponibles">
                        <div class="equipoItem">
                            <div class="equipoNombre">
                                {{ item.nombre }}
                            </div>
                            <div class="equipoCounter">
                                <button class="equipoMinus" (click)="minusEquipo(item);$event.preventDefault()" [attr.disabled]="bEdicion"><la-icon [icon]="['las','minus']"></la-icon></button>
                                <div class="equipoNumber">{{ item.cant }}</div>
                                <button class="equipoPlus" (click)="plusEquipo(item);$event.preventDefault()" [attr.disabled]="bEdicion"><la-icon [icon]="['las','plus']"></la-icon></button>
                            </div>
                        </div>
                    </div>
                </div>


                <div class="row text-dark pt-3">
                  <div class="col-lg-12">
                    <div class="buttonSelect text-center">
                      <button class="btn btn-danger mr-2" (click)="modal.dismiss()">Cerrar</button>
                      <button class="btn btn-danger mr-2" [ngClass]="hCancelar ? 'hide':''" (click)="cancelar()">Cancelar Reservación</button>
                      <button *ngIf="!bEdicion" class="btn btn-danger" (click)="guardar(2)" [disabled]="forma.invalid">Guardar</button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
  -->