<div class="bg-login">
  <div class="container">
    <div class="row align-items-center minh-100">
      <div class="col-md-3"></div>
      <div class="col-md-6">
        <div class="card shadow-lg border-0 rounded-lg" id="box-login">
          <div class="card-header">
            <h3 class="text-center font-weight-light my-4">Iniciar Sesión</h3>
          </div>
          <div class="card-body">

            <div *ngIf="!showLogin" class="d-flex align-items-center text-center justify-content-center"
              style="height: 335px">
              <h5>Accediendo a la cuenta de IBERO...</h5>
            </div>

            <form *ngIf="showLogin" autocomplete="nope" [formGroup]="flogin">
              <div class="form-group">
                <label class="small mb-1" for="idTipoUsuario">Tipo de Usuario</label>
                <select class="form-control" id="idTipoUsuario" name="idTipoUsuario"
                  (change)="DeterminarSiOcupamosDigitoVerificador($event)">
                  <option value="0"></option>
                  <option *ngFor="let tipoUsuario of tiposDeUsuario" value="{{tipoUsuario.idTipoUsuario}}">
                    {{tipoUsuario.descripcion}}</option>
                </select>
              </div>
              <div class="form-group"><label class="small mb-1" for="cuenta">Cuenta</label>
                <input autocomplete="nope" class="form-control py-4" id="cuenta" type="text"
                  placeholder="Ingrese su usuario" formControlName="cuenta" [(ngModel)]="mlogin.cuenta" />
              </div>
              <div class="form-group" *ngIf="MostrarDigitoVerificador"><label class="small mb-1"
                  for="digito">Digito</label>
                <input autocomplete="nope" class="form-control py-4" id="digito" type="text" placeholder="Digito"
                  formControlName="digito" [(ngModel)]="mlogin.digito" maxlength="1" />
              </div>
              <div class="form-group"><label class="small mb-1" for="clave">Contraseña</label>
                <input autocomplete="nope" class="form-control py-4" id="clave" type="password"
                  placeholder="Ingrese su contraseña" formControlName="clave" [(ngModel)]="mlogin.clave" />
              </div>
              <div class="form-group d-flex align-items-center justify-content-center mt-4 mb-0">
                <button class="btn btn-danger text-white" (click)="entrar()">Entrar</button>
              </div>


            </form>

          </div>

          <div class="small mb-1 text-center">
            <div class="small"><label class="text-white">Versión 2.1.0</label></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>