<div class="container">
    <div class="row justify-content-between py-2">
        <div class="col-md-4 col-sm-12">
            <h5 class="title-section">Reservaciones</h5>
        </div>
    </div>
    <div class="row justify-content-between">
        <div class="col-sm-2">
            <label for="dia"><span class="text-danger">*</span>Ver disponibilidad:</label>
        </div>
        <div class="col-sm-2">
            <div class="input-group">
                <input class="form-control" placeholder="Fecha" name="dp" [(ngModel)]="filtroFecha" ngbDatepicker
                    #d="ngbDatepicker" [readonly]="true">
                <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button"></button>

            </div>
        </div>
        <div class="col-sm-1">
            <button class="btn btn-danger" (click)="Evento_SeleccionarNuevaFechaFiltro();" ngbTooltip="Buscar"> <i
                    class="las la-search text-white"></i></button>
        </div>
        <div class="col-sm-7 text-right">
            <button type="button" class="btn btn-danger" style="margin-right: 8px;" (click)="goBack()" ngbTooltip="Regresar"><i class="las la-arrow-left"></i> Regresar</button>
            <button type="button" class="btn btn-danger"
                (click)="Evento_MostrarFormularioParaGenerarNuevaReservacion(content)" ngbTooltip="Nueva Reservación"><i
                    class="las la-calendar"></i>
                Nueva Reservación</button>
        </div>

    </div>
    <br>

    <google-chart [title]="chart.title" [type]="chart.type" [data]="chart.data" [columns]="chart.columnNames"
        [options]="chart.options" style="height: 664px;min-width: 100%;"
        (select)="Evento_SeleccionarReservacionParaEdicion($event, content);">
    </google-chart>
</div>

<ng-template #content let-modal>
    <div class="modal-hidden">
        <button class="close" aria-label="close" type="button" (click)="modal.dismiss();recargar()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="card border-dark mb-0">
            <div class="card-header text-white text-center bg-dark">Reservación</div>
            <div class="card-body text-dark">

                <div class=" card-body row">
                    <div class="col-12">
                        <form [formGroup]="forma">
                            <input class="form-control" type="hidden" formControlName="idReservacion"
                                [(ngModel)]="model.idReservacion">
                            <div class="form-group row">
                                <div class="col-md-2">
                                    <label for="dia"><span class="text-danger">*</span>Espacio</label>
                                </div>
                                <div class="col-md-5">
                                    <select class="form-control" formControlName="idEspacio"
                                        [(ngModel)]="model.idEspacio" (change)="setEspacio()"
                                        [attr.disabled]="bEdicion || EspacioD">
                                        <option value="">--Seleccionar--</option>
                                        <option *ngFor="let item of EspacioLista" [ngValue]="item.idEspacio">
                                            {{ item.nombre }} (Capacidad: {{ item.capacidad }})
                                        </option>
                                    </select>
                                    <div class="text-danger" *ngIf="!espacioNoDisponible && (forma.controls['idEspacio'].touched && forma.controls['idEspacio'].errors?.required)">
                                        Este campo es requerido
                                    </div>
                                    <div class="alert alert-info" role="alert" *ngIf="espacioNoDisponible">
                                        Ya no hay horarios disponibles para este día.
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row">
                                <div class="col-md-2">
                                    <label for="dia"><span class="text-danger">*</span>Día</label>
                                </div>
                                <div class="col-md-10">
                                    <div class="input-group">
                                        <input class="form-control" dateFormat="dd/mm/yyyy" placeholder="dd/mm/yyyy"
                                            [readonly]="true" [(ngModel)]="model.dia" (dateSelect)="setHorario($event)"
                                            [minDate]="CalMinDate" id="dia" formControlName="dia" ngbDatepicker
                                            #d="ngbDatepicker">
                                        <div class="input-group-append">
                                            <button class="btn btn-outline-secondary calendar" (click)="d.toggle()"
                                                type="button" [attr.disabled]="bEdicion || FechaD"></button>
                                        </div>
                                        <select class="form-control" formControlName="horaInicio"
                                            [(ngModel)]="model.horaInicio" (change)="setHoraFin()"
                                            [attr.disabled]="bEdicion || HorarioD">
                                            <option value="">--Seleccionar--</option>
                                            <option *ngFor="let item of HoraInicioLista" [ngValue]="item">
                                                {{ item }}
                                            </option>
                                        </select>
                                        <select class="form-control" formControlName="horaFin"
                                            [(ngModel)]="model.horaFin" (change)="setEquipos()"
                                            [attr.disabled]="bEdicion || HorarioFinD">
                                            <option value="">--Seleccionar--</option>
                                            <option *ngFor="let item of HoraFinLista" [ngValue]="item">
                                                {{ item }}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="text-danger"
                                        *ngIf="(forma.controls['dia'].touched && forma.controls['dia'].errors?.required) || (forma.controls['horaInicio'].touched && forma.controls['horaInicio'].errors?.required) || (forma.controls['horaFin'].touched && forma.controls['horaFin'].errors?.required)">
                                        Estos campos son requeridos
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row">
                                <div class="col-md-2">
                                    <label for="dia">Comentario</label>
                                </div>
                                <div class="col-md-10">
                                    <div class="input-group">
                                        <textarea cols="40" rows="2" class="form-control" formControlName="comentarios"
                                            [(ngModel)]="model.comentarios" [attr.disabled]="bEdicion"></textarea>
                                    </div>
                                </div>
                            </div>
                            <div class="row text-dark">
                                <div class="col-lg-12">
                                    <div class="buttonSelect text-center">
                                        <button class="btn btn-danger mr-2"
                                            (click)="modal.dismiss();recargar()">Cerrar</button>
                                        <button class="btn btn-danger mr-2" *ngIf="ShowBotonCancelar"
                                            [ngClass]="hCancelar ? 'hide':''" (click)="Evento_Cancelar()">Cancelar
                                            Reservación</button>
                                        <button class="btn btn-danger mr-2" *ngIf="ShowBotonGuardar"
                                            (click)="Evento_GuardarReservacion(1)"
                                            [disabled]="forma.invalid">Guardar</button>
                                        <button class="btn btn-danger mr-2" *ngIf="ShowBotonConfirmar"
                                            (click)="Evento_ConfirmarReservacion();">Confirmar</button>

                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>