<!--Contenedor-->
<div class="container align-items-center flex-column">
  <div class="row justify-content-between py-2">
    <div class="col-md-4 col-sm-12">
      <h5 class="title-section">Reservaciones / Histórico</h5>
    </div>
    <div class="col-md-4 col-sm-12 text-right">
      <button class="btn btn-danger mr-1" (click)="Evento_MostrarFormularioParaGenerarNuevaReservacion(content)"
        ngbTooltip="Nueva Reservación">
        <!--la-icon [icon]="['las','calendar-plus']" class="text-white"></la-icon-->
        <i class="las la-calendar-plus text-white"></i>
      </button>
      <button class="btn icon-btn text-right bg-secondary mr-1" ngbTooltip="Ver Calendario"
        [routerLink]="['/calendar-admin']">
        <!--la-icon [icon]="['las','arrow-left']" size="1x" class="text-white"></la-icon-->
        <i class="las la-calendar text-white"></i>
      </button>
      <button class="btn icon-btn text-right bg-secondary mr-1" ngbTooltip="Regresar" [routerLink]="['/menu-admin']">
        <!--la-icon [icon]="['las','arrow-left']" size="1x" class="text-white"></la-icon-->
        <i class="las la-arrow-left text-white"></i>

      </button>

    </div>
  </div>

  <div class="row g-3" style="width: 700px;">
    <div class="col">
      <div class="input-group mb-3">
        <input class="form-control" dateFormat="dd/mm/yyyy" placeholder="Fecha de Inicio" [readonly]="true"
          [(ngModel)]="fechaDeInicio" id="fechaInicio" ngbDatepicker #d="ngbDatepicker">
        <span class="input-group-append">
          <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button"></button>
        </span>
      </div>
    </div>
    <div class="col">
      <div class="input-group mb-3">
        <input class="form-control" dateFormat="dd/mm/yyyy" placeholder="Fecha Fin" [readonly]="true" id="fechaFin"
          [(ngModel)]="fechaFin" ngbDatepicker #a="ngbDatepicker">
        <span class="input-group-append">
          <button class="btn btn-outline-secondary calendar" (click)="a.toggle()" type="button"></button>
        </span>
      </div>
    </div>

    <div class="col">
      <div class="form-group">
      <select class="form-control" id="Estatus" [(ngModel)]="Estatus">
        <option value="0">Ver Todos</option>
        <option *ngFor="let item of EstatusLista" [ngValue]="item.idEstatus">
          Solo {{ item.descripcion }}
        </option>
      </select>

        <!--select class="form-control" id="Estatus" [(ngModel)]="Estatus">
          <option value="0">Todos</option>
          <option value="1">Solo Pendientes</option>
          <option value="2">Solo Confirmado</option>
          <option value="3">Solo Cancelados</option>
        </select-->
      </div>
    </div>

    <div class="text-center">
      <!--button class="iberoNormalTextButton rounded">
        <span class="iberoNormalButtonText" (click)="Evento_Filtrar()">Buscar</span>
      </button-->
      <button class="btn btn-danger" (click)="Evento_Filtrar()" ngbTooltip="Buscar"> <i
          class="las la-search text-white"></i></button>
    </div>

  </div>

  <div class="row f-13 bg-secondary text-white">
    <div class="col-md-2 text-center">Espacio</div>
    <div class="col-md-3 text-center">Usuario</div>
    <div class="col-md-2 text-center">Fecha</div>
    <div class="col-md-1 text-center">Inicio</div>
    <div class="col-md-1 text-center">Fin</div>
    <div class="col-md-2 text-center">Estatus</div>
    <div class="col-md-1 text-center">Acciones</div>
  </div>
  <div class="row f-13" [ngClass]="i%2 == 0 ? 'bg-dee':''"
    *ngFor="let item of ReservacionLista | paginate: { itemsPerPage: 15, currentPage: p }; let i = index">
    <div class="col-md-2 text-center">{{ item.espacio }}</div>
    <div class="col-md-3 text-center">{{ item.nombre}} {{ item.apPat}} {{ item.apMat}}</div>
    <div class="col-md-2 text-center">{{ item.fecha }} </div>
    <div class="col-md-1 text-center">{{ item.inicio }} </div>
    <div class="col-md-1 text-center">{{ item.fin }} </div>
    <div class="col-md-2 text-center">{{ item.estatus }}</div>
    <div class="col-md-1 text-center">
      <button [ngClass]="(item.finalizada || item.idEstatus==3) ? 'hide':''" class="btn icon-btn p-0"
        (click)="Evento_SeleccionarReservacionParaEdicion(content,item);"
        ngbTooltip="Editar Registro">
        <i class="las la-edit"></i>
      </button>
    </div>
  </div>

  <div class="row f-13 bg-secondary text-white">
    <div class="col-md-12 text-center">{{ (ReservacionLista)?.length }} Registros</div>
  </div>
  <pagination-controls (pageChange)="p = $event" previousLabel="Anterior" nextLabel="Siguiente" class="text-center">
  </pagination-controls>
</div>
<!--Formulario-->

<ng-template #content let-modal>
  <div class="modal-hidden">
    <button class="close" aria-label="close" type="button" (click)="modal.dismiss();recargar()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="card border-dark mb-0">
      <div class="card-header text-white text-center bg-dark">Reservación</div>
      <div class="card-body text-dark">

        <div class=" card-body row">
          <div class="col-12">
            <form [formGroup]="forma">
              <input class="form-control" type="hidden" formControlName="idReservacion"
                [(ngModel)]="model.idReservacion">
                <div class="form-group row">
                  <div class="col-md-2">
                    <label for="dia"><span class="text-danger">*</span>Espacio</label>
                  </div>
                  <div class="col-md-5">
                    <select class="form-control" formControlName="idEspacio" [(ngModel)]="model.idEspacio"
                      (change)="setEspacio()" [attr.disabled]="bEdicion || EspacioD">
                      <option value="">--Seleccionar--</option>
                      <option *ngFor="let item of EspacioLista" [ngValue]="item.idEspacio">
                        {{ item.nombre }} (Capacidad: {{ item.capacidad }})
                      </option>
                    </select>
                    <div class="text-danger"
                      *ngIf="forma.controls['idEspacio'].touched && forma.controls['idEspacio'].errors?.required">
                      Este campo es requerido</div>
                  </div>
                </div>
              <div class="form-group row">
                
                <div class="col-md-2">
                  <label for="dia"><span class="text-danger">*</span>Día</label>
                </div>
                <div class="col-md-10">
                  <div class="input-group">
                    <input class="form-control" dateFormat="dd/mm/yyyy" placeholder="dd/mm/yyyy" [readonly]="true"
                      [(ngModel)]="model.dia" (dateSelect)="setHorario($event)" [minDate]="CalMinDate" id="dia"
                      formControlName="dia" ngbDatepicker #d="ngbDatepicker">
                    <div class="input-group-append">
                      <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button"
                        [attr.disabled]="bEdicion || FechaD"></button>
                    </div>
                    <select class="form-control" formControlName="horaInicio" [(ngModel)]="model.horaInicio"
                      (change)="setHoraFin()" [attr.disabled]="bEdicion || HorarioD">
                      <option value="">--Seleccionar--</option>
                      <option *ngFor="let item of HoraInicioLista" [ngValue]="item">
                        {{ item }}
                      </option>
                    </select>
                    <select class="form-control" formControlName="horaFin" [(ngModel)]="model.horaFin"
                      (change)="setEquipos()" [attr.disabled]="bEdicion || HorarioFinD">
                      <option value="">--Seleccionar--</option>
                      <option *ngFor="let item of HoraFinLista" [ngValue]="item">
                        {{ item }}
                      </option>
                    </select>
                  </div>
                  <div class="text-danger"
                    *ngIf="!espacioNoDisponible && ((forma.controls['dia'].touched && forma.controls['dia'].errors?.required) || (forma.controls['horaInicio'].touched && forma.controls['horaInicio'].errors?.required) || (forma.controls['horaFin'].touched && forma.controls['horaFin'].errors?.required))">
                    Estos campos son requeridos
                  </div>
                  <div class="alert alert-info" role="alert" *ngIf="espacioNoDisponible">
                    Ya no hay horarios disponibles para este día.
                  </div>
                </div>
              </div>
              <div class="form-group row">
                <div class="col-md-2">
                  <label for="dia">Comentario</label>
                </div>
                <div class="col-md-10">
                  <div class="input-group">
                    <textarea cols="40" rows="2" class="form-control" formControlName="comentarios"
                      [(ngModel)]="model.comentarios" [attr.disabled]="bEdicion"></textarea>
                  </div>
                </div>
              </div>
              <div class="row text-dark">
                <div class="col-lg-12">
                  <div class="buttonSelect text-center">
                    <button class="btn btn-danger mr-2" (click)="modal.dismiss();recargar()">Cerrar</button>
                    <button class="btn btn-danger mr-2" *ngIf="ShowBotonCancelar" [ngClass]="hCancelar ? 'hide':''"
                      (click)="Evento_Cancelar()">Cancelar
                      Reservación</button>
                    <button class="btn btn-danger mr-2" *ngIf="ShowBotonGuardar" (click)="Evento_GuardarReservacion(1)"
                      [disabled]="forma.invalid">Guardar</button>
                    <button class="btn btn-danger mr-2" *ngIf="ShowBotonConfirmar"
                      (click)="Evento_ConfirmarReservacion();">Confirmar</button>

                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>