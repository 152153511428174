<!--Contenedor-->
<div class="container align-items-center flex-column">

  <!--Encabezado-->
  <div class="row justify-content-between py-2">
    <div class="col-md-4 col-sm-12">
      <h5 class="title-section">Equipos</h5>
    </div>
    <div class="col-md-4 col-sm-12 text-right">
      <button class="btn icon-btn text-right bg-secondary mr-2" ngbTooltip="Agregar" (click)="nuevo(content)">
        <!--la-icon [icon]="['las','plus']" size="1x" class="text-white"></la-icon-->
        <i class="las la-plus"></i>
      </button>
      <button class="btn icon-btn text-right bg-secondary" ngbTooltip="Regresar" [routerLink]="['/menu-admin']">
        <!--la-icon [icon]="['las','arrow-left']" size="1x" class="text-white"></la-icon-->
        <i class="las la-arrow-left"></i>
      </button>
    </div>
  </div>


  <div class="row f-13 bg-secondary text-white">
    <div class="col-md-6 text-center">Nombre</div>
    <div class="col-md-2 text-center">Cantidad</div>
    <div class="col-md-2 text-center">Estatus</div>
    <div class="col-md-2 text-center">Acciones</div>
  </div>
  <div class="row f-13" [ngClass]="i%2 == 0 ? 'bg-dee':''"
    *ngFor="let item of EquipoLista | paginate: { itemsPerPage: 10, currentPage: p }; let i = index">
    <div class="col-md-6 text-center">{{ item.nombre}}</div>
    <div class="col-md-2 text-center">{{ item.cantidad}}</div>
    <div class="col-md-2 text-center">{{ item.estatus?"Activo":"Inactivo" }}</div>
    <div class="col-md-2 text-center">
      <button class="btn icon-btn" (click)="editar(content, item)" ngbTooltip="Editar Registro">
        <i class="las la-edit text-dark"></i>
      </button>
    </div>
  </div>
  <div class="row f-13 bg-secondary text-white">
    <div class="col-md-12 text-center">{{ (EquipoLista)?.length }} Registros</div>
  </div>
  <pagination-controls (pageChange)="p = $event" previousLabel="Anterior" nextLabel="Siguiente" class="text-center">
  </pagination-controls>
</div>

<!--Formulario-->

<ng-template #content let-modal>
  <div class="modal-hidden">
    <button class="close" aria-label="close" type="button" (click)="modal.dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="card border-dark mb-0">
      <div class="card-header text-white text-center bg-dark">Datos del Equipo</div>
      <div class="card-body text-dark">

        <div class=" card-body row">
          <div class="col-12">
            <form [formGroup]="forma">
              <div class="row text-dark">
                <input class="form-control" type="hidden" formControlName="idEquipo" [(ngModel)]="model.idEquipo">
              </div>
              <div class="row text-dark">
                <div class="form-group col-lg-4">
                  <label for="nombre">Nombre:</label>
                  <input class="form-control" type="text" formControlName="nombre" [(ngModel)]="model.nombre">
                  <div class="text-danger"
                    *ngIf="forma.controls['nombre'].touched && forma.controls['nombre'].errors?.required">Este campo es
                    requerido</div>
                </div>
                <div class="form-group col-lg-4">
                  <label for="cantidad">Cantidad:</label>
                  <input class="form-control" type="number" formControlName="cantidad" [(ngModel)]="model.cantidad">
                  <div class="text-danger"
                    *ngIf="forma.controls['cantidad'].touched && forma.controls['cantidad'].errors?.required">Este campo
                    es requerido</div>
                  <div class="text-danger"
                    *ngIf="forma.controls['cantidad'].touched && forma.controls['cantidad'].errors?.min">La cantidad
                    debe ser mayor que 0</div>
                </div>
                <div class="form-group col-lg-4">
                  <label for="estatus">Estatus</label>
                  <select class="form-control" formControlName="estatus" [(ngModel)]="model.estatus">
                    <option value="">--Seleccionar--</option>
                    <option [ngValue]="true">Activo</option>
                    <option [ngValue]="false">Inactivo</option>
                  </select>
                  <div class="text-danger"
                    *ngIf="forma.controls['estatus'].touched && forma.controls['estatus'].errors?.required">Este campo
                    es requerido</div>
                </div>
              </div>
              <div class="row text-dark pt-3">
                <div class="col-lg-12">
                  <div class="buttonSelect text-center">
                    <button class="btn btn-danger" (click)="modal.dismiss()">Cancelar</button>&nbsp;
                    <button class="btn btn-danger" (click)="guardar()" [disabled]="forma.invalid">Guardar</button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>