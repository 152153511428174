import { Component, OnInit } from '@angular/core';
import { UsuarioService } from 'src/app/data-access/usuario.service'
import { Router, ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-calendar-admin',
  templateUrl: './calendar-admin.component.html',
  styleUrls: ['./calendar-admin.component.css']
})
export class CalendarAdminComponent implements OnInit {

  constructor(
    private router: Router,
    private usuarioService: UsuarioService,
  ) { 
    if(!this.usuarioService.adm()) this.router.navigate(['/menu']);
  }

  ngOnInit(): void {
  }

}
