import { Component, OnInit, Input } from '@angular/core';
import { ChangeDetectionStrategy, ViewChild, TemplateRef } from '@angular/core';
import { startOfDay, endOfDay, subDays, addDays, endOfMonth, isSameDay, isSameMonth, addHours } from 'date-fns';
import { Subject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { NgxSpinnerService } from "ngx-spinner";
import { UsuarioService } from 'src/app/data-access/usuario.service'
import { Router, ActivatedRoute } from '@angular/router';
import { EspacioTipoService } from 'src/app/data-access/espaciotipo.service';
import { EspacioService } from 'src/app/data-access/espacio.service';
import { ReservacionService } from 'src/app/data-access/reservacion.service';
import { EquipoService } from 'src/app/data-access/equipo.service';
import { Observable } from 'rxjs';
import { CalendarEvent, CalendarEventAction, CalendarEventTimesChangedEvent, CalendarView } from 'angular-calendar';
import { NgbDatepickerConfig, NgbCalendar, NgbDate, NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
import Swal from 'sweetalert2';
import { DatePipe } from '@angular/common';

export interface AutoCompleteModel {
  value: any;
  display: string;
}
interface Evento extends CalendarEvent {
  tipo: number;
  espacio: number;
}

const colors: any = {
  red: {
    primary: '#ad2121',
    secondary: '#FAE3E3',
  },
  blue: {
    primary: '#1e90ff',
    secondary: '#D1E8FF',
  },
  yellow: {
    primary: '#e3bc08',
    secondary: '#FDF1BA',
  },
};

@Component({
  selector: 'app-solicitud',
  templateUrl: './solicitud.component.html',
  styles: [
  ]
})
export class SolicitudComponent implements OnInit {
  forma: FormGroup;
  model;
  NombreCompleto;
  CalMinDate;
  p: number = 1;

  EspacioD = undefined;
  FechaD = undefined;
  HorarioD = undefined;
  HorarioFinD = undefined;
  UsuarioSeleccionado: string = "";

  datePickerJson = {};
  markDisabled;
  json = {
    disable: [6, 7],
    disabledDates: [
      { year: 2020, month: 8, day: 13 },
      { year: 2020, month: 8, day: 19 },
      { year: 2020, month: 8, day: 25 }
    ]
  };

  hCancelar;
  hConfirmar;
  bEdicion = false;
  EquiposDisponibles = [];
  fechaDeInicio: Date;
  fechaFin: Date;
  Estatus: number = 0;
  EsPrimeraVezQueIngresaElUsuario: boolean = true;
  idReservationAEditar: any;
  ShowBotonGuardar: boolean;
  ShowBotonCancelar: any;
  NoActualizarHorariosInicio: any;
  ShowBotonConfirmar: any;
  Usuario: any;
  isDisabled: (date: NgbDateStruct) => boolean;

  constructor(
    public fb: FormBuilder,
    private http: HttpClient,
    private router: Router,
    private usuarioService: UsuarioService,
    private espacioTipoService: EspacioTipoService,
    private espacioService: EspacioService,
    private reservacionService: ReservacionService,
    private equipoService: EquipoService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    public modal: NgbModal,
    private config: NgbDatepickerConfig,
    private calendar: NgbCalendar,
    public datepipe: DatePipe
  ) {
    if(!this.usuarioService.adm()) this.router.navigate(['/menu']);
    const current = new Date();
    this.CalMinDate = {
      year: current.getFullYear(),
      month: current.getMonth() + 1,
      day: current.getDate()
    };
    this.Usuario = this.usuarioService.getUser();
    let user = this.usuarioService.getUser();
    this.NombreCompleto = user.nombre + ' ' + user.apPat + ' ' + user.apMat;
    this.iniciarForm();
  }

  ngOnInit(): void {
    //this.addSeccion();
    //this.disableDays();
    this.addEspacioTipo();
    //this.addEspacio();
    this.addEquipo();
    this.addLista();
    this.addEstatus();
  }

  iniciarForm() {
    this.forma = this.fb.group({
      idReservacion: [0],
      idEspacioTipo: [0],
      idEspacio: [0],
      idUsuario: [0],
      dia: ['', [Validators.required]],
      horaInicio: ['', [Validators.required]],
      horaFin: ['', [Validators.required]],
      inicio: [''],
      fin: [''],
      idEstatus: [0],
      equipos: [''],
      equipo: [''],
      comentarios: ['1']
    })
    this.model = this.forma;
    this.UsuarioSeleccionado = "";
  }

  EspacioTipo;
  EspacioTipoLista;
  addEspacioTipo() {
    this.spinner.show();
    this.espacioTipoService.getData().toPromise()
      .then(data => {
        this.spinner.hide();
        this.EspacioTipo = data;
        this.EspacioTipoLista = this.EspacioTipo;
        this.addEspacio();
      });
  }

  Espacio;
  EspacioLista;
  addEspacio() {
    this.spinner.show();
    this.espacioService.getData().toPromise()
      .then(data => {
        this.spinner.hide();
        //this.addLista();
        this.Espacio = data;
        this.EspacioLista = this.Espacio;

      });
  }

  Equipo;
  EquipoLista;
  addEquipo() {
    this.spinner.show();
    this.equipoService.getData().toPromise()
      .then(data => {
        this.spinner.hide();
        this.Equipo = data;
        //display: 'Lámpara', value: 1
        for (let i = 0; i < this.Equipo.length; i++) {
          this.Equipo[i]['display'] = this.Equipo[i].nombre;
          this.Equipo[i]['value'] = this.Equipo[i].idEquipo;
        }
        this.EquipoLista = this.Equipo;
        //this.addLista();
      });
  }

  Reservacion;
  ReservacionLista;
  events: Evento[];
  addLista() {

    this.ObtenerInformacionDeReservaciones(null, null, -1);
  }

  //idEstatus;
  Evento_Filtrar() {
    //alert(this.idEstatus);
    //alert(this.Estatus);
    if (this.fechaDeInicio == null || this.fechaFin == null) {
      this.toastr.error("Las fechas ingresadas no son válidas.");
      return;
    }


    if (this.fechaDeInicio > this.fechaFin) {
      this.toastr.error("La fecha de inicio debe ser menor o igual a la fecha fin.");
      return;
    }
    this.ObtenerInformacionDeReservaciones(this.fechaDeInicio, this.fechaFin, this.Estatus);

  }

  DebemosRegresarTodosLosElementos(fechaInicio, fechaFin, idEstatus) {
    return fechaInicio == null && fechaFin == null && idEstatus == 0;
  }

  ObtenerInformacionDeReservaciones(fechaInicio, fechaFin, idEstatus) {


    this.spinner.show();

    let reservationSearchMethod: Observable<any> = null;

    reservationSearchMethod = this.reservacionService.ObtenerReservacionesPorFiltro(fechaInicio, fechaFin, idEstatus);

    reservationSearchMethod.toPromise()
      .then(data => {
        this.spinner.hide();
        this.Reservacion = data;
        this.ReservacionLista = this.Reservacion.map( reservacion => {
          var reservacion = {...reservacion}
          //console.log(reservacion);
          //const dateString = reservacion.inicio.split('T')[0];
          //const fecha = new Date(dateString);
          //const fecha1 = this.uf_Date(dateString);
          //const fecha2 = this.util_fdt(dateString);
          //const fecha = this.util_fdt(reservacion.inicio);
          //console.log(dateString);
          //console.log(fecha1);
          //console.log(fecha2);
          //console.log(fecha3);
          reservacion.fecha = this.uf_Date(reservacion.inicio);
          //reservacion.fecha = this.datepipe.transform(fecha, 'dd/MM/yyyy')
          reservacion.inicio = this.datepipe.transform(reservacion.inicio, 'hh:mm');
          reservacion.fin = this.datepipe.transform(reservacion.fin, 'hh:mm');
          //console.log(reservacion);
          return reservacion
        });
      });
  }

  Filtro;
  filtraTipo() {
    let Filtro = this.Espacio;

    //idNivel
    if (this.model.idEspacioTipo != 0 && this.model.idEspacioTipo !== undefined) {
      Filtro = Filtro
        .filter(x => x.idEspacioTipo === Number(this.model.idEspacioTipo));
    }


    this.EspacioLista = Filtro;

  }

  //Estatus;
  EstatusLista;
  addEstatus() {
    this.spinner.show();
    this.reservacionService.getEstatus().toPromise()
      .then(data => {
        this.spinner.hide();
        this.EstatusLista = data;
        console.log(this.EstatusLista);
      });
  }

  HoraInicioLista;
  HoraFinLista;
  /*setEspacio() {

    //Obtenemos el espacio para establecer las reglas de operación
    let et = this.Espacio.find(x => x.idEspacio == Number(this.model.idEspacio));
    //Obtenemos la lista de equipos que permite el espacio
    let arrEquipo = et.equipos.split(',');
    this.EquipoLista = this.Equipo.filter(x => arrEquipo.includes(x.idEquipo.toString()));
    //Asignamos la lista de equipos seleccionados por el usuario en el formato requerido

    if (this.model.equipos != '' && this.model.equipos != undefined && !Array.isArray(this.model.equipos)) {
      let arrSel = this.model.equipos.split(',');
      this.model.equipos = this.EquipoLista.filter(x => arrSel.includes(x.idEquipo.toString()));
    }



    let hora = JSON.parse(et.horario);
    let inicio = Number(hora.inicio.split(':')[0]);
    let fin = Number(hora.fin.split(':')[0]);
    //horarios
    let inicioLista = [];
    for (let i = inicio; i <= fin; i++) {
      let h = (i < 10 ? '0' + i : i)
      inicioLista.push({ horaInicio: h + ':00' });
    }
    this.HoraInicioLista = inicioLista;
    this.HoraFinLista = inicioLista;
  }*/

  setEspacio() {
    this.model.dia = '';
    this.model.horaInicio = '';
    this.model.horaFin = '';
    this.HorarioD = true;
    this.HorarioFinD = true;
    this.FechaD = true;
    if (this.model.idEspacio != 0 && this.model.idEspacio !== undefined) {
      this.FechaD = undefined;
    }
  }

  espacioNoDisponible = false
  setHorario() {
    this.model.horaInicio = '';
    this.model.horaFin = '';
    this.HorarioD = true;
    this.HorarioFinD = true;
    this.slideUpEquipos();
    var dia = this.forma.controls["dia"].value;
    var diaSel = this.formatDate(dia);
    this.spinner.show();

    this.reservacionService.getHorariosInicio({ IdEspacio: this.model.idEspacio, FechaReservacion: diaSel }).subscribe(
      res => {
        this.espacioNoDisponible = false
        this.HoraInicioLista = res;
        this.HorarioD = undefined;
        this.spinner.hide();
      },
      error => {
        this.espacioNoDisponible = true
        if (typeof error.error === 'object') {
          this.toastr.error(error.message);
        } else {
          this.toastr.error(error.error);
        }
        this.spinner.hide();
      }
    );

  }


  setEquipos() {
    if (this.model.horaFin != '' && this.model.horaFin !== undefined) {
      const dia = this.forma.controls["dia"].value;
      const diaSel = this.formatDate(dia);
      const horaInicio = this.forma.controls["horaInicio"].value;
      const horaFin = this.forma.controls["horaFin"].value;
      this.spinner.show();
      this.equipoService.equiposDisponibles({ IdEspacio: this.model.idEspacio, FechaReservacion: diaSel, HorarioInicio: horaInicio, HorarioFin: horaFin }).subscribe(
        res => {
          this.EquiposDisponibles = res.map(x => { x.cant = 0; return x });
          this.slideDownEquipos();
          this.spinner.hide();
        },
        error => {
          if (typeof error.error === 'object') {
            this.toastr.error(error.message);
          } else {
            this.toastr.error(error.error);
          }
          this.spinner.hide();
        }
      );
    } else {
      this.slideUpEquipos();
    }
  }



  minusEquipo(item) {
    if (item.cant > 0) {
      item.cant--;
    }
  }

  plusEquipo(item) {
    if (item.cant < item.cantidad) {
      item.cant++;
    }
  }

  setHoraFin() {
    this.model.horaFin = '';
    this.HorarioFinD = true;
    this.slideUpEquipos();
    if (this.model.horaInicio != '' && this.model.horaInicio !== undefined) {
      var dia = this.forma.controls["dia"].value;
      var diaSel = this.formatDate(dia);
      var horaInicio = this.forma.controls["horaInicio"].value;

      this.spinner.show();
      this.reservacionService.getHorariosFin({ 
        IdEspacio: this.model.idEspacio, 
        IdReservacion: this.model.idReservacion ?? 0,
        FechaReservacion: diaSel, 
        HorarioInicio: horaInicio 
      }).subscribe(
        res => {
          this.HoraFinLista = res;
          this.HorarioFinD = undefined;
          this.bEdicion = undefined;
          this.spinner.hide();
        },
        error => {
          if (typeof error.error === 'object') {
            this.toastr.error(error.message);
          } else {
            this.toastr.error(error.error);
          }
          this.spinner.hide();
        }
      );
    }
  }

  slideDownEquipos() {
    $('#equipoContent').slideDown({
      start: () => {
        $('#equipoContent').css({ display: 'flex' });
      }
    });
  }

  slideUpEquipos() {
    $('#equipoContent').slideUp(400, () => {
      this.EquiposDisponibles = [];
    });
  }

  nuevo(content) {
    this.espacioNoDisponible = false
    this.bEdicion = false;
    this.iniciarForm();
    this.model.activo = true;
    this.ShowBotonCancelar = false;
    this.modal.open(content, { size: 'xl' });
  }

  Evento_MostrarFormularioParaGenerarNuevaReservacion(content) {
    this.espacioNoDisponible = false
    this.iniciarForm();
    this.restoreEspacioLista();
    this.model.activo = true;
    this.hCancelar = true;
    this.bEdicion = undefined;
    this.FechaD = true;
    this.HorarioD = true;
    this.HorarioFinD = true;
    this.ShowBotonGuardar = true;
    this.ShowBotonCancelar = false;
    this.ShowBotonConfirmar = false;
    this.NoActualizarHorariosInicio = false;
    this.modal.open(content, { size: 'xl' });
  }


  editar(content, modelo) {
    this.espacioNoDisponible = false
    this.HoraInicioLista = [modelo.horaInicio];
    this.HoraFinLista = [modelo.horaFin];
    this.model = modelo;
    this.restoreEspacioLista();
    this.hCancelar = false;
    this.bEdicion = true;
    this.model.dia = new Date(modelo.inicio);
    this.modal.open(content, { size: 'xl' });
    console.log(this.model);

  }

  restoreEspacioLista() {
    this.EspacioLista = this.Espacio;
  }


  formatDate(fecha) {
    var d = new Date(fecha);
    //d.setHours(0, 0, 0, 0);
    return [
      d.getFullYear(),
      ('0' + (d.getMonth() + 1)).slice(-2),
      ('0' + d.getDate()).slice(-2),

    ].join('-');
  }

  uf_Date(fecha) {
    var d = new Date(fecha);
    //d.setHours(0, 0, 0, 0);
    return [
      ('0' + d.getDate()).slice(-2),
      ('0' + (d.getMonth() + 1)).slice(-2),
      d.getFullYear()
    ].join('/');
  }

  Evento_Buscar() {

    let cuentaABuscar = this.forma.controls["cuenta"].value;
    if (cuentaABuscar == undefined) {
      this.toastr.error("Cuenta no válida.");
      return;
    }

    this.usuarioService.ObtenerInformacionAdicionalDelUsuario(this.forma.controls["cuenta"].value, 24)
      .toPromise().then(result => {
        if (result == null) {
          this.toastr.error("No se pudo encontrar el usuario.");
        } else {
          let resultado: any = result;
          this.UsuarioSeleccionado = resultado.nombre + ' ' + resultado.apPat + ' ' + resultado.apMat;
          console.log(resultado);
        }
      });



  }

  Evento_GuardarReservacion(idEstatus, confirmar = false) {
    this.spinner.show();
    this.dEnable(true);
    this.Usuario = this.usuarioService.getUser();
    var dia = this.forma.controls["dia"].value;
    var hinicio = this.forma.controls["horaInicio"].value;
    var hfin = this.forma.controls["horaFin"].value;
    var idEspacio = this.forma.controls["idEspacio"].value;
    var dInicio = this.formatDate(dia);
    var inicio = dInicio + 'T' + hinicio + ':00';
    var fin = dInicio + 'T' + hfin + ':00';
    this.forma.controls["inicio"].setValue(inicio);
    this.forma.controls["fin"].setValue(fin);
    this.forma.controls["idUsuario"].setValue(this.Usuario.idUsuario);

    //Si no es una cancelación, determinamos el estatus según el espacio
    if (idEstatus != 3) {
      let espacio = this.Espacio.find(x => x.idEspacio === idEspacio);
      if (espacio.autorizacion) { idEstatus = 1; }
    }

    this.forma.controls["idEstatus"].setValue(idEstatus);



    let arr = [];
    /*this.EquiposDisponibles = arr;
    for (let e of this.EquiposDisponibles) {
      if (e.cant > 0) {
        e.cantidad = e.cant;
        arr.push(e);
      }
    }*/

    this.forma.controls["equipos"].setValue(arr);

    if (this.forma.controls["idReservacion"].value == undefined) {
      this.reservacionService.addData(this.forma.value).subscribe(
        res => {
          this.recargar();
          this.spinner.hide();
          this.toastr.success("La información ha sido enviada");
        },
        error => {
          if (typeof error.error === 'object') {
            this.toastr.error(error.message);
          } else {
            this.toastr.error(error.error);
          }
          this.spinner.hide();
        });
    }
    else {
      this.reservacionService.GetReservacion(this.idReservationAEditar).toPromise().then(element => {
        console.log(element);
        this.forma.controls["idEstatus"].setValue(element.idEstatus);

        this.reservacionService.updData(this.forma.controls["idReservacion"].value, this.forma.value).subscribe(
          (res) => {
            this.toastr.success("La información ha sido actualizada");
            this.recargar();
            if (confirmar) {
              this.reservacionService.ConfirmarReservacion(this.idReservationAEditar).toPromise().then(element => {
                this.toastr.success("Reservación ha sido confirmada");
                this.recargar();
              });
            }

            this.spinner.hide();
          },
          (error) => { this.toastr.error(error.error.message); console.log(error); this.spinner.hide(); });
      });

    }

  }

  Evento_ConfirmarReservacion() {
    this.spinner.show();

    this.reservacionService.GetReservacion(this.idReservationAEditar).toPromise().then(element => {
      this.Evento_GuardarReservacion(element.idEstatus, true);
    });


  }

  Evento_Cancelar() {
    Swal.fire({
      title: 'Cancelar Reservacion',
      input: 'textarea',
      text: "Se cancelará la reservación. Déjanos un comentario con el motivo de la cancelación.",
      icon: 'warning',
      inputAttributes: {
        autocapitalize: 'off'
      },
      showCancelButton: true,
      confirmButtonColor: '#dc3545',
      confirmButtonText: 'Cancelar Reservación',
      cancelButtonText: 'Cancelar',
      showLoaderOnConfirm: true,
      preConfirm: (comentario) => {
        this.spinner.show();
        this.reservacionService.cancelarReservacion({ IdReservacion: this.model.idReservacion, MotivoCancelacion: comentario }).subscribe(
          res => {
            this.spinner.hide();
            this.toastr.success("La reservación ha sido cancelada"); this.recargar();
          },
          error => {
            if (typeof error.error === 'object') {
              this.toastr.error(error.message);
            } else {
              this.toastr.error(error.error);
            }
            this.spinner.hide();
          }
        );
        return true;
      }
    });
  }

  disableDays() {
    //to disable specific date and specific weekdays
    this.isDisabled = (
      date: NgbDateStruct
      //current: { day: number; month: number; year: number }
    ) => {
      return this.json.disabledDates.find(x =>
        (new NgbDate(x.year, x.month, x.day).equals(date))
        || (this.json.disable.includes(this.calendar.getWeekday(new NgbDate(date.year, date.month, date.day))))
      )
        ? true
        : false;
    };
  }

  recargar() {
    this.modal.dismissAll();
    this.addLista();
  }

  util_fdt(fecha) {
    var d = new Date(fecha);
    return d.toLocaleString();
  }



  minDate: Date = new Date();
  dateIsValid(date: Date): boolean {
    return date >= this.minDate;
  }

  Evento_SeleccionarReservacionParaEdicion(content, item) {
    console.log(item);
    this.idReservationAEditar = item.idReservacion;
    this.Evento_MostrarFormularioParaEditarReservacion(content);
  }

  UserSelectedInvalidReservation(): boolean {
    return this.idReservationAEditar == 0;
  }


  Evento_MostrarFormularioParaEditarReservacion(content) {
    this.espacioNoDisponible = false
    if (this.UserSelectedInvalidReservation()) { return; }

    let user = this.usuarioService.getUser();
    let idUsuario = user.idUsuario;
    this.ShowBotonGuardar = false;

    this.reservacionService.EvaluarReservacion(this.idReservationAEditar, idUsuario)
      .toPromise()
      .then(elements => {
        console.log(elements);
        if (elements.mostrarMensajeDeError) {
          this.toastr.error(elements.mensajeDeError);
        }

        this.ShowBotonGuardar = elements.mostrarBotonGuardar;
        this.ShowBotonCancelar = elements.mostrarBotonCancelar;
        this.NoActualizarHorariosInicio = elements.noActualizarListadoDeHorariosInicio;
        this.ShowBotonConfirmar = elements.mostrarBotonConfirmar;

        console.log(this.idReservationAEditar);
        this.reservacionService.GetReservacion(this.idReservationAEditar)
          .toPromise()
          .then(data => {
            this.model.comentarios = data.comentarios;
            this.model.idEspacio = data.idEspacio;
            let elements = data.inicio.split('T');
            let fechaElement = elements[0];
            let tiempo = elements[1];
            let anio = fechaElement.split('-')[0];
            let mes = fechaElement.split('-')[1];
            let dia = fechaElement.split('-')[2];

            console.log(this.usuarioService.getUser().idUsuario);
            let tiempofin = data.fin.split('T')[1];
            this.model.dia = dia + '/' + mes + '/' + anio;
            this.model.horaInicio = tiempo.split(':')[0] + ':' + tiempo.split(':')[1];
            this.model.horaFin = tiempofin.split(':')[0] + ':' + tiempofin.split(':')[1];
            this.model.inicio = data.inicio;
            this.model.fin = data.fin;
            this.model.idUsuario = this.usuarioService.getUser().idUsuario;
            this.model.idEstatus = data.idEstatus;
            this.model.idReservacion = data.idReservacion;
            this.Evento_EditarReservacion(content, this.model);
          });
      });
  }

  Evento_EditarReservacion(content, modelo) {
    this.HoraInicioLista = [modelo.horaInicio];
    this.HoraFinLista = [modelo.horaFin];
    this.model = modelo;
    this.restoreEspacioLista();
    this.hCancelar = false;
    //this.bEdicion = true;
    this.model.dia = new Date(modelo.inicio);
    this.forma = this.model;

    /*Inicializar Horarios Inicio */
    var dia = this.model.dia;
    var diaSel = this.formatDate(dia);

    if (!this.NoActualizarHorariosInicio) {
      this.reservacionService.getHorariosInicio({ IdEspacio: this.model.idEspacio, FechaReservacion: diaSel, idReservacion: this.model.idReservacion }).subscribe(
        res => {

          this.HoraInicioLista = res;
          this.HorarioD = undefined;
          this.spinner.hide();
        },
        error => {
          if (typeof error.error === 'object') {
            this.toastr.error(error.message);
          } else {
            this.toastr.error(error.error);
          }
          this.spinner.hide();
        }
      );

    }
    this.dEnable(false);
    this.modal.open(content, { size: 'xl' });

  }

  dEnable(flag){
    if(flag){
      this.forma.get('idEspacio')?.enable();
      this.forma.get('dia')?.enable();
      this.forma.get('horaInicio')?.enable();
      this.forma.get('horaFin')?.enable();
    }
    else{
      this.forma.get('idEspacio')?.disable();
      this.forma.get('dia')?.disable();
      this.forma.get('horaInicio')?.disable();
      this.forma.get('horaFin')?.disable();
    }
  }  

}



