<div *ngIf="ls.validar(); then thenTemplateName else elseTemplateName">
  Here is never showing
</div>

<ng-template #thenTemplateName>
  <div class="d-flex" id="wrapper">
    <div id="page-content-wrapper">
      <div class="bg-login">
        <div class="container-fluid">
          <div class="row align-items-center minh-100">
            <div class="col-md-12">
              <div class="card shadow-lg border-0 rounded-lg mt-2" id="box-login">
                <app-nav-menu></app-nav-menu>
                <div class="card-body">
                  <router-outlet></router-outlet>
                </div>
                <!--div class="footer rounded-bottom">
                  <span class="text-justify "><a href="../assets/IberoAvisoDePrivacidad2022.pdf">Aviso de
                      privacidad</a></span>
                </div-->
                <div class="footer rounded-bottom pb-4">
                  Biblioteca                                
                  <!-- Botón de Modal Aviso de privacidad -->
                  <!--button type="button" class="btn btn-outline-link text-white shadow-none pb-2" data-toggle="modal" data-target="#Avisoprivacidad">
                    Aviso de Privacidad
                  </button-->
                </div> 
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #elseTemplateName>
  <router-outlet></router-outlet>
</ng-template>

<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="timer" [fullScreen]="true">
  <p style="color: white"> cargando... </p>
</ngx-spinner>

<!-- Modal Aviso de Privacidad -->
<div class="modal fade" id="Avisoprivacidad" tabindex="-1" role="dialog" aria-labelledby="Avisoprivacidad" aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header text-center bg-ibero-rojo">
        <h5 class="modal-title text-white" id="exampleModalLabel">Aviso de privacidad</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="embed-responsive embed-responsive-16by9">
          <iframe class="embed-responsive-item" src="../assets/IberoAvisoDePrivacidad2022.pdf" allowfullscreen></iframe>
        </div>
      </div>
      <div class="modal-footer text-center">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
        <!--button-- type="button" class="btn btn-primary">Save changes</!--button-->
      </div>
    </div>
  </div>
</div>