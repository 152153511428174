<div class="card-header text-center text-white" [ngClass]="bgapp">
  <img src="../../../../assets/img/login/{{logo}}" class="img-login mr-2" id="img-login" />
  <button class="btn icon-btn text-right bg-white mr-2" [routerLink]="['/menu']" ngbTooltip="Usuario" *ngIf="!us.adm()">
    <!--la-icon [icon]="'laptop-medical'" size="1x" class="text-danger"></la-icon-->
    <i class="las la-laptop-medical text-danger navMenu"></i>
  </button>
  <button class="btn icon-btn text-right bg-white" [routerLink]="['/menu-admin']" ngbTooltip="Administrador"
    *ngIf="us.adm()">
    <!--la-icon [icon]="'cog'" size="1x" class="text-danger"></la-icon-->
    <i class="las la-cog text-danger navMenu"></i>
  </button>
  <p class="mb-0">{{NombreCompleto}}
    <button class="btn icon-btn text-right ml-1" ngbTooltip="Salir" (click)="logout()">
      <!--la-icon [icon]="['las','sign-out-alt']" size="1x" class="text-white"></la-icon-->
      <i class="las la-sign-out-alt text-white "></i>
    </button>
  </p>
</div>