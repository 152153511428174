import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, ActivationStart, Router, RoutesRecognized } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from "ngx-spinner";
import { FormBuilder, FormGroup, Validators, FormControl, AbstractControl } from '@angular/forms';
import { UsuarioService } from 'src/app/data-access/usuario.service';
import { GrupoService } from 'src/app/data-access/grupo.service';
import * as myGlobals from 'src/app/data-access/util';
import { DOCUMENT } from '@angular/common';
import { AuthService } from 'src/app/data-access/auth.service';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html'
})

export class LoginComponent implements OnInit {
    flogin: FormGroup | any;
    mlogin: FormGroup | any;
    showLogin = true;
    notReady = true;
    Cuenta: any;
    CuentaLista: any;
    Empleado;
    EmpleadoLista;
    cuenta;
    clave;
    idRol;
    tiposDeUsuario: any;
    MostrarDigitoVerificador: boolean = false;
    listaDeIdsDeTiposDeUsuarioQueRequierenDigitoVerificador;

    constructor(
        @Inject(DOCUMENT) private document: Document,
        private authService: AuthService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        public fb: FormBuilder,
        private usuarioService: UsuarioService,
        private grupoService: GrupoService,
        private toastr: ToastrService,
        private spinner: NgxSpinnerService
    ) {
        localStorage.setItem('valido', '0');

        this.iniciarForm();

        this.usuarioService.ObtenerListadoDeTiposDeUsuario()
            .toPromise()
            .then(result => this.tiposDeUsuario = result);

        this.usuarioService.ObtenerIdsDeTiposDeUsuariosQueRequierenDigitoVerificador()
            .toPromise()
            .then(result => this.listaDeIdsDeTiposDeUsuarioQueRequierenDigitoVerificador = result);
    }

    ngOnInit() {

        document.getElementById("cuenta")?.focus();
        this.iniciarForm();
    }

    iniciarForm() {
        this.flogin = this.fb.group({
            cuenta: ['', Validators.required],
            clave: ['', Validators.required],
            idTipoUsuario: [-1, Validators.required],
            digito: ['']
        });
        this.mlogin = this.flogin;
    }

    entrar() {
        this.spinner.show();
        this.cuenta = this.flogin.controls["cuenta"].value;

        this.usuarioService.loginAccount(this.flogin.value).toPromise().then(data => {
            this.acceso(data);
        })
    }

    acceso(data) {
        this.Cuenta = data;
        this.spinner.hide();
        console.log(this.Cuenta);

        if (this.Cuenta.validoExterno) {

            localStorage.clear();
            this.usuarioService.addUser(this.Cuenta);
            localStorage.setItem('valido', '1');
            localStorage.setItem('user', JSON.stringify(this.Cuenta));
            this.authService.verifyExpirationTime()
            this.router.navigate(['/menu']);
        } else {
            this.toastr.warning(this.Cuenta.message);
            return false;
        }
    }

    DeterminarSiOcupamosDigitoVerificador(evento) {
        let idTipoDeUsuarioSeleccionado = evento.target.value;

        for (let i = 0; i < this.listaDeIdsDeTiposDeUsuarioQueRequierenDigitoVerificador.length; i++) {
            if (this.listaDeIdsDeTiposDeUsuarioQueRequierenDigitoVerificador[i] == idTipoDeUsuarioSeleccionado) {
                this.MostrarDigitoVerificador = true;
                return;
            }
        }

        this.MostrarDigitoVerificador = false;
        return;

    }



}
