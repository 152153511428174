<!--Contenedor-->
<div class="container align-items-center flex-column">

  <!--Encabezado-->
  <!--div class="text-center pt-2">
      <h4 class="title-section">Espacios</h4>
    </div-->

  <!--Listado-->
  <div class="row justify-content-between py-2">
    <div class="col-md-4 col-sm-12">
      <h5 class="title-section">Espacios</h5>
    </div>
    <div class="col-md-4 col-sm-12 text-right">
      <button class="btn icon-btn text-right bg-secondary mr-2" ngbTooltip="Agregar" (click)="nuevo(content)">
        <!--la-icon [icon]="['las','plus']" size="1x" class="text-white"></la-icon-->
        <i class="las la-plus text-white"></i>
      </button>
      <button class="btn icon-btn text-right bg-secondary" ngbTooltip="Regresar" [routerLink]="['/menu-admin']">
        <!--la-icon [icon]="['las','arrow-left']" size="1x" class="text-white"></la-icon-->
        <i class="las la-arrow-left text-white"></i>
      </button>
    </div>
  </div>



  <div class="row f-13 bg-secondary text-white">
    <div class="col-md-3 text-center">Nombre</div>
    <div class="col-md-1 text-center">Clave</div>
    <div class="col-md-1 text-center">Capacidad</div>
    <div class="col-md-1 text-center">Máx. Hrs.</div>
    <!--div class="col-md-1 text-center">Equipos</div-->
    <div class="col-md-2 text-center">Horario</div>
    <div class="col-md-1 text-center">Req. Auto.</div>
    <div class="col-md-1 text-center">Notifica</div>
    <div class="col-md-1 text-center">Estatus</div>
    <div class="col-md-1 text-center">Acciones</div>
  </div>
  <div class="row f-13" [ngClass]="i%2 == 0 ? 'bg-dee':''"
    *ngFor="let item of EspacioLista | paginate: { itemsPerPage: 10, currentPage: p }; let i = index">
    <div class="col-md-3 text-center">{{ item.nombre}}</div>
    <div class="col-md-1 text-center">{{ item.claveCubiculo }}</div>
    <div class="col-md-1 text-center">{{ item.capacidad }}</div>
    <div class="col-md-1 text-center">{{ item.maximo }}</div>
    <!--div class="col-md-1 text-center">{{ item.eLista }}</div-->
    <div class="col-md-2 text-center">{{ getObject(item.horario) }} Hrs.</div>
    <div class="col-md-1 text-center">{{ item.autorizacion?"Si":"No" }}</div>
    <div class="col-md-1 text-center">{{ item.aviso?"Si":"No" }}</div>
    <div class="col-md-1 text-center">{{ item.estatus?"Activo":"Inactivo" }}</div>
    <div class="col-md-1 text-center">
      <button class="btn icon-btn" (click)="editar(content, item)" ngbTooltip="Editar Registro">
        <!--la-icon [icon]="['las','edit']" class="text-dark"></la-icon-->
        <i class="las la-edit text-dark"></i>
      </button>
    </div>
  </div>
  <div class="row f-13 bg-secondary text-white">
    <div class="col-md-12 text-center">{{ (EspacioLista)?.length }} Registros</div>
  </div>
  <pagination-controls (pageChange)="p = $event" previousLabel="Anterior" nextLabel="Siguiente" class="text-center">
  </pagination-controls>


</div>

<!--Formulario-->

<ng-template #content let-modal>
  <div class="modal-hidden">
    <button class="close" aria-label="close" type="button" (click)="modal.dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="card border-dark mb-0">
      <div class="card-header text-white text-center bg-dark">Datos del espacio</div>
      <div class="card-body text-dark">

        <div class=" card-body row">
          <div class="col-12">
            <form [formGroup]="forma">
              <div class="row text-dark">
                <input class="form-control" type="hidden" formControlName="idEspacio" [(ngModel)]="model.idEspacio">
              </div>
              <div class="row text-dark">

                <div class="form-group col-lg-6">
                  <label for="nombre"><span class="text-danger">*</span>Nombre:</label>
                  <input class="form-control" type="text" formControlName="nombre" [(ngModel)]="model.nombre">
                  <div class="text-danger"
                    *ngIf="forma.controls['nombre'].touched && forma.controls['nombre'].errors?.required">
                    Este campo es requerido
                  </div>
                </div>



                <div class="form-group col-lg-6">
                  <label for="ubicacion"><span class="text-danger">*</span>Ubicación:</label>
                  <input class="form-control" type="text" formControlName="ubicacion" [(ngModel)]="model.ubicacion">
                  <div class="text-danger" *ngIf="forma.controls['ubicacion'].touched && forma.controls['ubicacion'].errors?.required">
                    Este campo es requerido
                  </div>
                </div>

                <div class="form-group col-lg-3">
                  <label for="capacidad"><span class="text-danger">*</span>Capacidad:</label>
                  <input class="form-control" type="number" formControlName="capacidad" [(ngModel)]="model.capacidad">
                  <div class="text-danger" *ngIf="forma.controls['capacidad'].touched && forma.controls['capacidad'].errors?.required">Este campo es requerido</div>
                  <div class="text-danger" *ngIf="forma.controls['capacidad'].touched && forma.controls['capacidad'].errors?.min">La capacidad debe ser mayor que 0</div>
                  <div class="text-danger" *ngIf="forma.controls['capacidad'].touched && forma.controls['capacidad'].errors?.max">La capacidad debe ser menor que 99</div>
                </div>
                <div class="form-group col-lg-3">
                  <label for="maximo"><span class="text-danger">*</span>Máximo (Hrs.):</label>
                  <input class="form-control" type="number" formControlName="maximo" [(ngModel)]="model.maximo">
                  <div class="text-danger" *ngIf="forma.controls['maximo'].touched && forma.controls['maximo'].errors?.required">
                    Este campo es requerido
                  </div>
                  <div class="text-danger" *ngIf="forma.controls['maximo'].touched && forma.controls['maximo'].errors?.min">El máximo debe ser mayor que 0</div>
                  <div class="text-danger" *ngIf="forma.controls['maximo'].touched && forma.controls['maximo'].errors?.max">El máximo debe ser menor que 24</div>
                  <div class="text-danger" *ngIf="forma.controls['maximo'].touched && forma.controls['maximo'].errors?.pattern">El máximo debe ser un número entero</div>
                </div>
                <div class="form-group col-lg-3">
                  <label for="activo"><span class="text-danger">*</span>Hora Inicio:</label>
                  <select class="form-control" formControlName="horaInicio" [(ngModel)]="model.horaInicio"
                  (change)="setHoraFin()" >
                  <option value="">--Seleccionar--</option>
                  <option *ngFor="let item of HoraInicioLista" [ngValue]="item">
                    {{ item }}
                  </option>
                </select>
                  <div class="text-danger" *ngIf="forma.controls['horaInicio'].touched && forma.controls['horaInicio'].errors?.required">Este campo es requerido</div>
                </div>
                <div class="form-group col-lg-3">
                  <label for="activo"><span class="text-danger">*</span>Hora Fin:</label>
                  <select class="form-control" formControlName="horaFin" [(ngModel)]="model.horaFin">
                  <option value="">--Seleccionar--</option>
                  <option *ngFor="let item of HoraFinLista" [ngValue]="item">
                    {{ item }}
                  </option>
                  </select>
                  <div class="text-danger" *ngIf="forma.controls['horaFin'].touched && forma.controls['horaFin'].errors?.required">Este campo es requerido</div>
                </div>   
                <!--div class="form-group col-lg-3">
                  <label for="horaInicio">Hora Inicio:</label>
                  <input class="form-control" type="time" step="3600" value="08:00" formControlName="horaInicio"
                    [(ngModel)]="model.horaInicio">
                  <div class="text-danger"
                    *ngIf="forma.controls['horaInicio'].touched && forma.controls['horaInicio'].errors?.required">Este
                    campo es requerido</div>
                </div>
                <div class="form-group col-lg-3">
                  <label for="horaFin">Hora Fin:</label>
                  <input class="form-control" type="time" value="08:00" formControlName="horaFin"
                    [(ngModel)]="model.horaFin">
                  <div class="text-danger"
                    *ngIf="forma.controls['horaFin'].touched && forma.controls['horaFin'].errors?.required">Este campo
                    es requerido</div>
                </div-->

                <div class="form-group col-lg-3">
                  <label for="activo"><span class="text-danger">*</span>Requiere Confirmación</label>
                  <select class="form-control" formControlName="autorizacion" [(ngModel)]="model.autorizacion">
                    <option value="">--Seleccionar--</option>
                    <option [ngValue]="true">Si</option>
                    <option [ngValue]="false">No</option>
                  </select>
                  <div class="text-danger"
                    *ngIf="forma.controls['autorizacion'].touched && forma.controls['autorizacion'].errors?.required">
                    Este campo es requerido</div>
                </div>

                <div class="form-group col-lg-3">
                  <label for="aviso"><span class="text-danger">*</span>Aviso a Administrador</label>
                  <select class="form-control" formControlName="aviso" [(ngModel)]="model.aviso">
                    <option value="">--Seleccionar--</option>
                    <option [ngValue]="true">Si</option>
                    <option [ngValue]="false">No</option>
                  </select>
                  <div class="text-danger"
                    *ngIf="forma.controls['aviso'].touched && forma.controls['aviso'].errors?.required">Este campo es
                    requerido</div>
                </div>
                <div class="form-group col-lg-3">
                  <label for="activo"><span class="text-danger">*</span>Clave Biostar</label>
                  <select class="form-control" formControlName="idCubiculo" [(ngModel)]="model.idCubiculo">
                    <option value="">--Seleccionar--</option>
                    <option *ngFor="let item of CubiculoLista" [ngValue]="item.idCubiculo">
                      {{ item.clave }}
                    </option>
                  </select>
                  <div class="text-danger" *ngIf="forma.controls['idCubiculo'].touched && forma.controls['idCubiculo'].errors?.required">Este campo es requerido</div>
                </div>
                <div class="form-group col-lg-3">
                  <label for="activo"><span class="text-danger">*</span>Estatus</label>
                  <select class="form-control" formControlName="estatus" [(ngModel)]="model.estatus">
                    <option value="">--Seleccionar--</option>
                    <option [ngValue]="true">Activo</option>
                    <option [ngValue]="false">Inactivo</option>
                  </select>
                  <div class="text-danger"
                    *ngIf="forma.controls['estatus'].touched && forma.controls['estatus'].errors?.required">Este campo
                    es requerido</div>
                </div>

                <!--div class="form-group col-lg-6">
                  <label for="activo">IdEspacioBioStar</label>
                  <input class="form-control" type="text" formControlName="idEspacioBioStar"
                    [(ngModel)]="model.idEspacioBioStar">
                  <div class="text-danger"
                    *ngIf="forma.controls['idEspacioBioStar'].touched && forma.controls['idEspacioBioStar'].errors?.required">
                    Este campo es
                    requerido</div>
                </div-->





              </div>

              <div class="row text-dark pt-3">
                <div class="col-lg-12">
                  <div class="buttonSelect text-center">
                    <button class="btn btn-danger" (click)="modal.dismiss()">Cancelar</button>&nbsp;
                    <button class="btn btn-danger" (click)="guardar()" [disabled]="forma.invalid">Guardar</button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>