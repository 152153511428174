<!--Contenedor-->
<div class="container align-items-center flex-column">



  <!--Listado-->
  <div class="row justify-content-between py-2">
    <div class="col-md-4 col-sm-12">
      <h5 class="title-section">Mis Reservaciones</h5>
    </div>
    <div class="col-md-4 col-sm-12 text-right">
      <button class="btn btn-danger mr-1" (click)="Evento_MostrarFormularioParaGenerarNuevaReservacion(content)"
        ngbTooltip="Nueva Reservación">
        <!--la-icon [icon]="['las','calendar-plus']" class="text-white"></la-icon-->
        <i class="las la-calendar-plus"></i>
      </button>
      <button class="btn btn-danger mr-1" [routerLink]="['/e-calendario/']" ngbTooltip="Calendario">
        <!--la-icon [icon]="['las','calendar']" class="text-white"></la-icon-->
        <i class="las la-calendar"></i>
      </button>
      <button class="btn btn-danger mr-1" ngbTooltip="Histórico" [routerLink]="['/e-historico/']">
        <!--la-icon [icon]="['las','book']" class="text-white"></la-icon-->
        <i class="las la-book"></i>
      </button>
      <button class="btn btn-danger text-right bg-secondary" ngbTooltip="Regresar" [routerLink]="['/menu']">
        <!--la-icon [icon]="['las','arrow-left']" size="1x" class="text-white"></la-icon-->
        <i class="las la-arrow-left"></i>
      </button>
    </div>
  </div>

  <div class="row f-13 bg-secondary text-white">
    <div class="col-md-2 text-center">Espacio</div>
    <div class="col-md-3 text-center">Usuario</div>
    <div class="col-md-2 text-center">Inicio</div>
    <div class="col-md-2 text-center">Fin</div>
    <div class="col-md-2 text-center">Estatus</div>
    <div class="col-md-1 text-center">Acciones</div>
  </div>
  <div class="row f-13" [ngClass]="i%2 == 0 ? 'bg-dee':''"
    *ngFor="let item of ReservacionLista | paginate: { itemsPerPage: 10, currentPage: p }; let i = index ">
    <div class="col-md-2 text-center">{{ item.espacio }}</div>
    <div class="col-md-3 text-center">{{ item.nombre}} {{ item.apPat}} {{ item.apMat}}</div>
    <div class="col-md-2 text-center">{{ uf_Date(item.inicio) }} {{item.horaInicio}} Hrs.</div>
    <div class="col-md-2 text-center">{{ uf_Date(item.fin) }} {{item.horaFin}} Hrs.</div>
    <div class="col-md-2 text-center">{{ item.estatus }}</div>
    <div class="col-md-1 text-center">
      <button [ngClass]="(item.idEstatus==3 || item.finalizada) ? 'hide':''" class="btn icon-btn p-0"
        (click)="Evento_SeleccionarReservacionParaEdicion(content,item);"
        ngbTooltip="Editar Registro">
        <!--la-icon [icon]="['las','edit']" class="text-dark"></la-icon-->
        <i class="las la-edit text-dark"></i>
      </button>
    </div>
  </div>
  <div class="row f-13 bg-secondary text-white">
    <div class="col-md-12 text-center">{{ (ReservacionLista)?.length }} Registros</div>
  </div>
  <pagination-controls (pageChange)="p = $event" previousLabel="Anterior" nextLabel="Siguiente" class="text-center">
  </pagination-controls>

</div>

<!--Formulario-->
<ng-template #content let-modal>
  <div class="modal-hidden">
    <button class="close" aria-label="close" type="button" (click)="modal.dismiss();recargar()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="card border-dark mb-0">
      <div class="card-header text-white text-center bg-dark">Reservación</div>
      <div class="card-body text-dark">

        <div class=" card-body row">
          <div class="col-12">
            <form [formGroup]="forma">
              <input class="form-control" type="hidden" formControlName="idReservacion"
                [(ngModel)]="model.idReservacion">
                <div class="form-group row">
                  <div class="col-md-2">
                    <label for="dia"><span class="text-danger">*</span>Espacio</label>
                  </div>
                  <div class="col-md-5">
                    <select class="form-control" formControlName="idEspacio" [(ngModel)]="model.idEspacio"
                      (change)="setEspacio()" [attr.disabled]="bEdicion || EspacioD" [disabled]="true">
                      <option value="">--Seleccionar--</option>
                      <option *ngFor="let item of EspacioLista" [ngValue]="item.idEspacio">
                        {{ item.nombre }} (Capacidad: {{ item.capacidad }})
                      </option>
                    </select>
                    <div class="text-danger"
                      *ngIf="forma.controls['idEspacio'].touched && forma.controls['idEspacio'].errors?.required">
                      Este
                      campo es requerido</div>
                  </div>
                </div>
              <div class="form-group row">
                
                <div class="col-md-2">
                  <label for="dia"><span class="text-danger">*</span>Día</label>
                </div>
                <div class="col-md-10">
                  <div class="input-group">
                    <input class="form-control" dateFormat="dd/mm/yyyy" placeholder="dd/mm/yyyy" [readonly]="true"
                      [(ngModel)]="model.dia" (dateSelect)="setHorario($event)" [minDate]="CalMinDate" id="dia"
                      formControlName="dia" ngbDatepicker #d="ngbDatepicker">
                    <div class="input-group-append">
                      <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button"
                        [attr.disabled]="bEdicion || FechaD"></button>
                    </div>
                    <select class="form-control" formControlName="horaInicio" [(ngModel)]="model.horaInicio"
                      (change)="setHoraFin()" [attr.disabled]="bEdicion || HorarioD">
                      <option value="">--Seleccionar--</option>
                      <option *ngFor="let item of HoraInicioLista" [ngValue]="item">
                        {{ item }}
                      </option>
                    </select>
                    <select class="form-control" formControlName="horaFin" [(ngModel)]="model.horaFin"
                      (change)="setEquipos()" [attr.disabled]="bEdicion || HorarioFinD">
                      <option value="">--Seleccionar--</option>
                      <option *ngFor="let item of HoraFinLista" [ngValue]="item">
                        {{ item }}
                      </option>
                    </select>
                  </div>
                  <div class="text-danger"
                    *ngIf="!espacioNoDisponible && ((forma.controls['dia'].touched && forma.controls['dia'].errors?.required) || (forma.controls['horaInicio'].touched && forma.controls['horaInicio'].errors?.required) || (forma.controls['horaFin'].touched && forma.controls['horaFin'].errors?.required))">
                    Estos campos son requeridos
                  </div>
                  <div class="alert alert-info" role="alert" *ngIf="espacioNoDisponible">
                    Ya no hay horarios disponibles para este día.
                  </div>
                </div>
              </div>
              <div class="form-group row">
                <div class="col-md-2">
                  <label for="dia">Comentario</label>
                </div>
                <div class="col-md-10">
                  <div class="input-group">
                    <textarea cols="40" rows="2" class="form-control" formControlName="comentarios"
                      [(ngModel)]="model.comentarios" [attr.disabled]="bEdicion"></textarea>
                  </div>
                </div>
              </div>
              <div class="row text-dark">
                <div class="col-lg-12">
                  <div class="buttonSelect text-center">
                    <button class="btn btn-danger mr-2" (click)="modal.dismiss();recargar()">Cerrar</button>
                    <button class="btn btn-danger mr-2" *ngIf="ShowBotonCancelar" [ngClass]="hCancelar ? 'hide':''"
                      (click)="Evento_Cancelar()">Cancelar
                      Reservación</button>
                    <button class="btn btn-danger mr-2" *ngIf="ShowBotonGuardar" (click)="Evento_GuardarReservacion(1)"
                      [disabled]="forma.invalid">Guardar</button>
                    <button class="btn btn-danger mr-2" *ngIf="ShowBotonConfirmar"
                      (click)="Evento_ConfirmarReservacion();">Confirmar</button>

                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>