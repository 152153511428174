import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TagInputModule } from 'ngx-chips';

import { RouterModule } from '@angular/router';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NgbModule, NgbDateAdapter, NgbDateNativeAdapter } from '@ng-bootstrap/ng-bootstrap';
import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';
import { NgxCaptureModule } from 'ngx-capture';



import { PerfilUsuarioComponent } from './perfil-usuario/perfil-usuario.component';
import { ECalendarioComponent } from './e-calendario/e-calendario.component';
import { EReservacionComponent } from './e-reservacion/e-reservacion.component';
import { EHistoricoComponent } from './e-historico/e-historico.component';
import { MenuComponent } from './menu/menu.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { TimelineComponent } from '../general/timeline/timeline.component';
import { GoogleChartsModule } from 'angular-google-charts';

@NgModule({
  declarations: [PerfilUsuarioComponent, ECalendarioComponent, EReservacionComponent, EHistoricoComponent, MenuComponent, TimelineComponent],
  imports: [
    CommonModule,
    TagInputModule,
    RouterModule,
    NgbModule,
    ReactiveFormsModule,
    FormsModule,
    NgxQRCodeModule,
    NgxCaptureModule,
    NgxPaginationModule,
    GoogleChartsModule,
    CalendarModule.forRoot({ provide: DateAdapter, useFactory: adapterFactory })
  ],
  providers: [{
    provide: NgbDateAdapter,
    useClass: NgbDateNativeAdapter
  }],
  exports: [
    TimelineComponent
  ]
})

export class EstudianteModule {
  constructor() {

  }
}
