import { CalendarAdminComponent } from './feature/admin/calendar-admin/calendar-admin.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { LoginComponent } from './feature/shell/login/login.component';
import { PerfilComponent } from './feature/shell/perfil/perfil.component';
import { ECalendarioComponent } from './feature/estudiante/e-calendario/e-calendario.component';
import { EReservacionComponent } from './feature/estudiante/e-reservacion/e-reservacion.component';
import { RolComponent } from './feature/admin/rol/rol.component';
import { UsuarioComponent } from './feature/admin/usuario/usuario.component';
import { EspacioComponent } from './feature/admin/espacio/espacio.component';
import { EquipoComponent } from './feature/admin/equipo/equipo.component';
import { EHistoricoComponent } from './feature/estudiante/e-historico/e-historico.component';
import { SolicitudComponent } from './feature/admin/solicitud/solicitud.component';
import { EspaciotipoComponent } from './feature/admin/espaciotipo/espaciotipo.component';
import { InicioComponent } from './feature/shell/inicio/inicio.component';
import { MenuComponent } from './feature/estudiante/menu/menu.component';
import { MenuAdminComponent } from './feature/admin/menu-admin/menu-admin.component';
import { DiaInhabilComponent } from './feature/admin/diainhabil/diainhabil.component';
import { PerfilUsuarioComponent } from './feature/estudiante/perfil-usuario/perfil-usuario.component';
import { UsuarioTipoComponent } from './feature/admin/usuario-tipo/usuario-tipo.component';
import { ReporteComponent } from './feature/admin/reporte/reporte.component';
import { InactivoComponent } from './feature/shell/inactivo/inactivo.component';
import { NotFoundComponent } from './not-found/not-found.component';

const routes: Routes = [
  //{ path: '', component: LoginComponent},
  //{ path: '', component: LoginComponent },
  { path: 'inicio/:id', component: InicioComponent },
  { path: 'inicio', component: InicioComponent },
  { path: 'menu', component: MenuComponent },
  { path: 'menu-admin', component: MenuAdminComponent },
  { path: 'perfil', component: PerfilComponent },
  { path: 'login', component: LoginComponent },
  { path: 'rol', component: RolComponent },
  { path: 'usuario', component: UsuarioComponent },
  { path: 'usuario-tipo', component: UsuarioTipoComponent },
  { path: 'espacio', component: EspacioComponent },
  { path: 'espaciotipo', component: EspaciotipoComponent },
  { path: 'equipo', component: EquipoComponent },
  { path: 'diainhabil', component: DiaInhabilComponent },
  { path: 'solicitud', component: SolicitudComponent },
  { path: 'perfil-usuario', component: PerfilUsuarioComponent },
  { path: 'e-calendario', component: ECalendarioComponent },
  { path: 'e-reservacion', component: EReservacionComponent },
  { path: 'e-historico', component: EHistoricoComponent },
  { path: 'inactivo', component: InactivoComponent },
  { path: 'reporte', component: ReporteComponent },
  { path: 'calendar-admin', component: CalendarAdminComponent },
  { path: '*', component: InicioComponent },
  { path: '**', component: NotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
