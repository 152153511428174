import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from "ngx-spinner";
import { FormBuilder, FormGroup, Validators, FormControl, AbstractControl } from '@angular/forms';
import { UsuarioService } from 'src/app/data-access/usuario.service';
import { TipoUsuarioService } from 'src/app/data-access/tipoUsuario.service'
import { Router } from '@angular/router';
//import { RolService } from 'src/app/data-access/rol.service'

@Component({
  selector: 'app-perfil-usuario',
  templateUrl: './perfil-usuario.component.html',
  styles: [
  ]
})
export class PerfilUsuarioComponent implements OnInit {
  fprincipal: FormGroup;
  mprincipal;
  constructor(
    public fb: FormBuilder,
    private usuarioService: UsuarioService,
    private tipoUsuarioService: TipoUsuarioService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private router: Router
  ) {
    //this.iniciarForm();
    let isAdmin = this.usuarioService.adm();
    if(isAdmin){
      this.router.navigate(['/menu-admin'])
    } 
  }

  Usuario;
  ngOnInit(): void {
    this.Usuario = this.usuarioService.getUser();
    this.iniciarForm();
    console.log(this.Usuario);
    this.Usuario.idTipoUsuario = parseInt(this.Usuario.tipo);
    this.mprincipal = this.Usuario;
    this.addTipoUsuario();
  }

  iniciarForm() {
    this.fprincipalIniciar();
  }

  fprincipalIniciar() {
    //Principal
    this.fprincipal = this.fb.group({
      idUsuario: [0],
      idTipoUsuario: [{value: '', disabled: true},0],
      cuenta: [''],
      digito: [''],
      token: [''],
      nombre: [''],
      apPat: [''],
      apMat: [''],
      email: [''],
      claveDepartamento: [''],
      departamento: [''],
      claveCoordinacion: [''],
      coordinacion: [''],
      roles: [''],
      estatus: true
    });
    this.mprincipal = this.fprincipal;
    //this.mprincipal = this.Usuario;
  }

  TipoUsuarioLista;
  TipoUsuario;
  addTipoUsuario()
  {
    this.spinner.show();
    this.tipoUsuarioService.getData().toPromise()
      .then(data => {
        this.TipoUsuario = data;
        console.log(this.TipoUsuario);
        this.TipoUsuarioLista = this.TipoUsuario;
        this.spinner.hide();
      })
  } 

}
