import { TimelineComponent } from './../general/timeline/timeline.component';
import { EstudianteModule } from './../estudiante/estudiante.module';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { TagInputModule } from 'ngx-chips';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NgbModule, NgbDateAdapter, NgbDateNativeAdapter } from '@ng-bootstrap/ng-bootstrap';
import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';
import { NgxCaptureModule } from 'ngx-capture';



import { UsuarioComponent } from './usuario/usuario.component';
import { RolComponent } from './rol/rol.component';
import { EspacioComponent } from './espacio/espacio.component';
import { EquipoComponent } from './equipo/equipo.component';
import { SolicitudComponent } from './solicitud/solicitud.component';
import { DiaInhabilComponent } from './diainhabil/diainhabil.component';

import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { EspaciotipoComponent } from './espaciotipo/espaciotipo.component';
import { MenuAdminComponent } from './menu-admin/menu-admin.component';
import { UsuarioTipoComponent } from './usuario-tipo/usuario-tipo.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { AgGridModule } from 'ag-grid-angular';
import { ReporteComponent } from './reporte/reporte.component';
import { CalendarAdminComponent } from './calendar-admin/calendar-admin.component';



@NgModule({
  declarations: [UsuarioComponent, RolComponent, EspacioComponent, EquipoComponent,
    SolicitudComponent, EspaciotipoComponent, MenuAdminComponent, DiaInhabilComponent,
    UsuarioTipoComponent,
    ReporteComponent,
    CalendarAdminComponent],
  imports: [
    BrowserModule,
    CommonModule,
    TagInputModule,
    RouterModule,
    NgbModule,
    ReactiveFormsModule,
    FormsModule,
    NgxQRCodeModule,
    NgxCaptureModule,
    NgxPaginationModule,
    CalendarModule.forRoot({ provide: DateAdapter, useFactory: adapterFactory }),
    AgGridModule.withComponents([]),
    EstudianteModule
  ],
  providers: [{
    provide: NgbDateAdapter,
    useClass: NgbDateNativeAdapter
  }]
})
export class AdminModule {
  constructor() {

  }
}
